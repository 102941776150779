import React from "react";
import classes from "./bot.module.scss";

type Props = {
  id: number;
  botName: string;
  selectedBot: string;
  setSelectedBot: any;
  icon: string;
  activeIcon: string;
};

const Bot = (props: Props) => {
  const { botName, selectedBot, setSelectedBot, icon, activeIcon } = props;
  return (
    <div className={classes.bot}>
      <div className={classes.botImg} onClick={() => setSelectedBot(botName)}>
        <img src={selectedBot === botName ? activeIcon : icon} alt="icon" />
      </div>
      <p style={{ color: selectedBot === botName ? "#ffffff" : "#00000040" }}>
        {botName}
      </p>
    </div>
  );
};

export default Bot;
