import React from "react";
import { Link } from "react-router-dom";
import { ADDJOB } from "../../routes/routeConstants";
import styles from "./DashboardSetup.module.css";

const DashboardSetup = () => {
  return (
    <section className={styles.pageContainer}>
      <h2>Setup Your Dashboard</h2>
      <p>Let’s craft and setup your tools to grow your business</p>
      <form className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="companyName">Company Name</label>
          <input
            type="text"
            name="name"
            id="companyName"
            placeholder="Shekau Transit"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="coverage">Top Coverage Areas</label>
          <input
            type="text"
            name="coverage"
            id="coverage"
            placeholder="Lagos, Abuja, Ogun"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="delivery">Top Delivery Issues</label>
          <input
            type="text"
            name="delivery"
            id="delivery"
            placeholder="Riders keep eating food packages"
          />
        </div>
        <Link to={`/${ADDJOB}`}>
          <button type="button">Demo Now</button>
        </Link>
      </form>
    </section>
  );
};

export default DashboardSetup;
