import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utilities/helpers";
import MoreView from "../moreView/MoreView";
import classes from "./jobCard.module.scss";

interface JobCardProps {
  active: boolean;
  hasNext: boolean;
  data: any;
}

const JobCard = (props: JobCardProps) => {
  const { active, hasNext, data } = props;
  const [showMoreView, setShowMoreView] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const spanRef: any = useRef();
  const closeMoreView = () => setShowMoreView(false);

  const openCancel = () => setShowCancel(true);
  const closeCancel = () => {
    setShowCancel(false);
    setShowMoreView(false);
  };

  useEffect(() => {
    const closeMoreViewFunc = (e: any) => {
      if (e.path[1] !== spanRef.current) {
        closeMoreView();
      }
    };

    document.body.addEventListener("click", closeMoreViewFunc);

    return () => document.body.removeEventListener("click", closeMoreViewFunc);
  }, []);

  return (
    <div
      className={`${classes.jobCard} ${active && classes.active} ${
        !hasNext && classes.noNext
      }`}
    >
      <span>{data.id}</span>
      <div className={classes.jobInfo}>
        <div className={classes.dest_more}>
          <div className={classes.destination}>
            <p>{data.package_description}</p>
            <span>In Transit with Sunday Ilaje</span>
          </div>
          <span
            ref={spanRef}
            className={classes.more}
            onClick={() => setShowMoreView(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              fill="currentColor"
              className="bi bi-three-dots"
              viewBox="0 0 16 16"
            >
              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
            </svg>
          </span>
        </div>
        <div className={classes.time_edit}>
          <div className={classes.time}>
            <div className={classes.arrival}>
              <p>{formatDate(data.delivery_date)}</p>
              <p>8:12AM</p>
              <p>Arrival Time</p>
            </div>
            <div className={classes.departure}>
              <p>Wed Aug 10</p>
              <p>8:12AM</p>
              <p>Departure Time</p>
            </div>
          </div>
          <Link to="edit">
            <span className={classes.edit}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13px"
                fill="currentColor"
                className="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
              </svg>
            </span>
          </Link>
        </div>
        {showMoreView && (
          <MoreView
            closeFunction={closeMoreView}
            openCancelFunction={openCancel}
            reference={spanRef}
          />
        )}
        {showCancel && (
          <div className={classes.cancel}>
            <span>Are you sure you want to cancel?</span>
            <div>
              <p className={classes.yes} onClick={closeCancel}>
                Yes
              </p>
              <p className={classes.no} onClick={closeCancel}>
                No
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCard;
