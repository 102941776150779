import React from "react";
import useMobileView from "../../hooks/useMobileView";
import classes from "./callToAction.module.scss";

const CallToAction = () => {
  const mobileViewWidth = useMobileView();
  return (
    <div className={`${classes.Cta}`}>
      <h2>
        <span>Modern and Optimized </span> <br className={classes.break} />{" "}
        delivery infrastructure <br className={classes.break} /> for Africa.
      </h2>
      <p>
        Motionspace is helping business owners, consumers, and{" "}
        <br className={classes.break} /> couriers improve how they send and
        receive packages in Africa.
      </p>

      {mobileViewWidth > 991 ? (
        <div className={classes.actionButton}>
          <p>Ready to optimize your logistic experience?</p>
          <button>
            <a href="#footer">Join Our Waitlist</a>
          </button>
        </div>
      ) : (
        <div className={classes.actionButtonMobile}>
          <p>Ready to optimize your logistic experience?</p>
          <button>
            <a href="#footer">Join Our Waitlist</a>
          </button>
        </div>
      )}
    </div>
  );
};

export default CallToAction;
