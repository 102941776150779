import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { Container } from "react-bootstrap";
import { Navigate, Route, Routes } from "react-router-dom";
import DeliveryJobs from "../../components/mainAppComponents/deliveryJobs/DeliveryJobs";
import MobileNav from "../../components/mainAppComponents/mobileNav/MobileNav";
import {
  ADDJOB,
  ADDRIDER,
  MYDELIVERIES,
  HOME,
  JOBS,
  MAPS,
  REPORTS,
  RIDERS,
  ROUTES,
  SETTINGS,
  SETUP,
  TRACKPACKAGE,
  APIPLUGIN,
  BOTS,
  MANUALINPUT,
} from "../../routes/routeConstants";
import AddNewJob from "../addNewJob/AddNewJob";
import AddRider from "../addRider/AddRider";
import DashboardSetup from "../dashboardSetup/DashboardSetup";
import ApiPlugin from "../InputChanels/apiPlugin/ApiPlugin";
import BotChannel from "../InputChanels/botChannel/BotChannel";
import ManualJob from "../InputChanels/Manual Job/ManualJob";
import Riders from "../Riders/Riders";

import classes from "./dashboard.module.scss";
import AllDeliveries from "./deliveries/AllDeliveries/AllDeliveries";
import OptimizeDeliveires from "./deliveries/AllDeliveries/OptimizeDeliveires";
import Deliveries from "./deliveries/Deliveries";
import MyDeliveries from "./deliveries/MyDeliveries/MyDeliveries";
import Succesful from "./deliveries/Successful/Succesful";
import TrackPackage from "./deliveries/TrackPackage/TrackPackage";
import Home from "./home/Home";
import Maps from "./Maps/Maps";

const DashboardPage = () => {
  return (
    <>
      <Container className={classes.page}>
        <Routes>
          <Route path={`${RIDERS}/${ADDRIDER}`} element={<AddRider />} />
          <Route path={`${RIDERS}`} element={<Riders />} />
          <Route path={`${ADDJOB}`} element={<AddNewJob />} />
          <Route path={`${ADDJOB}/${APIPLUGIN}`} element={<ApiPlugin />} />
          <Route path={`${ADDJOB}/${BOTS}/:type`} element={<BotChannel />} />
          <Route path={`${ADDJOB}/${MANUALINPUT}`} element={<ManualJob />} />
          <Route path={`${SETUP}`} element={<DashboardSetup />} />
          <Route path={`${MAPS}`} element={<Maps />} />
          <Route path={`${ROUTES}`} element={<h1>Routes</h1>} />
          <Route path={`${REPORTS}`} element={<h1>Reports</h1>} />
          <Route path={`${SETTINGS}`} element={<h1>settings</h1>} />
          <Route path={`${HOME}/*`} element={<Home />} />
          <Route path={`${MYDELIVERIES}`} element={<MyDeliveries />} />
          <Route path="/test" element={<DeliveryJobs />} />
          <Route path={`${TRACKPACKAGE}/:id`} element={<TrackPackage />} />
          <Route path={`${JOBS}`} element={<Deliveries />}>
            <Route index element={<AllDeliveries />} />
            <Route path="optimize" element={<OptimizeDeliveires />} />
            <Route path="successful" element={<Succesful />} />
          </Route>
          <Route path="*" element={<Navigate to={`${HOME}`} />} />
        </Routes>
      </Container>
      <MobileNav />
    </>
  );
};

export default DashboardPage;
