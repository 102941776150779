import React from "react";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import dispatch from "../../assets/images/Desktop-Dispatch Management tool Asset.png";
import mobilethirdslide from "../../assets/images/Group 61.png";
import leftCaret from "../../assets/images/left caret.svg";
import radar from "../../assets/images/Motions Trust-radar.gif";
import rightCaret from "../../assets/images/right caret.svg";
import useMobileView from "../../hooks/useMobileView";
import classes from "./carouselBanner.module.scss";

function SamplePrevArrow(props: any) {
  const mobileViewWidth = useMobileView();
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={leftCaret}
      alt="prev"
      style={{
        ...style,
        width: mobileViewWidth > 768 ? "35px" : "25px",
        height: mobileViewWidth > 768 ? "35px" : "25px",
        boxShadow: "1px 2px 5px rgba(83, 77, 77, 0.25)",
        borderRadius: "50%",
        padding: mobileViewWidth > 768 ? "10px" : "5px",
      }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props: any) {
  const mobileViewWidth = useMobileView();
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={rightCaret}
      alt="next"
      style={{
        ...style,
        width: mobileViewWidth > 768 ? "35px" : "25px",
        height: mobileViewWidth > 768 ? "35px" : "25px",
        boxShadow: "1px 2px 5px rgba(83, 77, 77, 0.25)",
        borderRadius: "50%",
        padding: mobileViewWidth > 768 ? "10px" : "5px",
      }}
      onClick={onClick}
    />
  );
}

const CarouselBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className={classes.carouselBannerContainer}>
      <h3>Powering efficiency for everyone</h3>
      <br />

      <Row className="d-flex align-items-center justify-content-center">
        <Col lg={9}>
          <Slider
            {...settings}
            autoplay={true}
            arrows={true}
            className={`${classes.slider}`}
            dotsClass={`slick-dots ${classes.dots}`}
          >
            <div className="d-flex flex-column align-items-center justify-content-between">
              <img
                src={radar}
                alt="group "
                className={`${classes.slideImg} group `}
              />
              <h4 className={classes.mixedH4}>360&#xb0; Trust System</h4>
              <p className={classes.mixedP}>
                Rebuilding trust between Logistics companies, Vendors and
                Customers with our transparent tracking system where all parties
                can view and track the status of their packages to confirm
                timely delivery.
              </p>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-between">
              <img
                src={dispatch}
                alt="delivery man"
                className={classes.slideImg}
              />
              <h4>Dispatch managment tools</h4>
              <p>
                Coordinate your dispatch with our AI-enabled pick-up & drop-off
                optimization software. Reduce inefficiencies and Increase your
                average daily delivered orders. Leverage our secure network of
                shared locker infrastructures for fast, structured pick-ups and
                deliveries on your route.
              </p>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-between">
              <img
                src={mobilethirdslide}
                alt="map location"
                className={classes.slideImg}
              />
              <h4>Multi-Location Smart Delivery lockers</h4>
              <p className={classes.mapLocate}>
                Automated pick-up and drop-off points within a 1 to 3km walking
                radius of your home or office. Get access to our lockers
                strategically positioned at convenient publicly known landmarks
                to eliminate missed deliveries and poor last-mile address
                issues.
              </p>
            </div>
          </Slider>
        </Col>
      </Row>
    </div>
  );
};

export default CarouselBanner;
