import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import facebookImg from "../../assets/icon/Facebook White Icon.svg";
import instagramImg from "../../assets/icon/Instagram white icon.svg";
import LinkedinImg from "../../assets/icon/Linkedin White Icon.svg";
import twitterImg from "../../assets/icon/Twitter White Icon.svg";
import CarouselBanner from "../../components/CarouselBanner/CarouselBanner";
import Hero from "../../components/Hero/Hero";
import MappingBanner from "../../components/MappingBanner/MappingBanner";
import SingleBagBanner from "../../components/SingleBagBanner/SingleBagBanner";
import TapeBanner from "../../components/TapeBanner/TapeBanner";
import useInput from "../../hooks/useInput";
import useMobileView from "../../hooks/useMobileView";
import useWaitingList from "../../hooks/useWaitingList";
import { useAppSelector } from "../../redux/store";
import { validators } from "../../utilities/validators";
import classes from "./Landingpage.module.scss";

const Landingpage = () => {
  const mobileViewWidth = useMobileView();
  const {
    waiting: { waitingListInfo },
  } = useAppSelector((state) => state);
  const { setValuesToState } = useWaitingList();
  const navigate = useNavigate();
  const {
    value: email,
    inputBlurHandler,
    valueInputChange,
    valueIsValid,
    hasError,
  } = useInput(validators.checkEmailIsValid, waitingListInfo?.email ?? "");

  const onSubmitMail = (e: any) => {
    e.preventDefault();
    if (!valueIsValid) {
      return;
    }
    setValuesToState({ ...waitingListInfo, email });
    navigate("/fillform");
  };
  return (
    <div className={`${classes.container} container-fluid`}>
      <Hero />
      <div className={classes.myspacing}></div>
      <Container fluid className={classes.pageBContainer}>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col lg={10}>
            <SingleBagBanner />
          </Col>
        </Row>
        <div className={`${classes.myspacing} ${classes.map_spacer}`}></div>
        <Row>
          <Col lg={12}>
            <MappingBanner />
          </Col>
        </Row>
        <div className={classes.myspacing}></div>
        <Row className={classes.carousel_row}>
          <Col lg={12}>
            <CarouselBanner />
          </Col>
        </Row>
      </Container>

      <Container fluid className={classes.pageBContainer}>
        <Row>
          <Col lg={10}>
            <TapeBanner />
          </Col>
        </Row>
      </Container>
      <footer id="footer" className={classes.footer}>
        <Container
          fluid
          className={mobileViewWidth > 500 ? classes.pageBContainer : ""}
        >
          <Row>
            <Col lg={12}>
              <form onSubmit={onSubmitMail}>
                <label htmlFor="email">
                  Ready to optimize your logistic experience?
                </label>
                <div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={valueInputChange}
                    onBlur={inputBlurHandler}
                    value={email}
                    className={hasError ? classes.error : ""}
                    placeholder="Enter your email address"
                  />

                  <button
                    className={valueIsValid ? "" : classes.disabled}
                    disabled={!valueIsValid}
                    type="submit"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Join Waitlist
                  </button>
                </div>

                <div className={classes.socialLinks}>
                  <a href="#!">
                    <img src={twitterImg} alt="twitter" />
                  </a>
                  <a href="#!">
                    <img src={facebookImg} alt="facebook" />
                  </a>
                  <a href="#!">
                    <img src={instagramImg} alt="instagram" />
                  </a>
                  <a href="#!">
                    <img src={LinkedinImg} alt="linkedin" />
                  </a>
                </div>

                <p className={classes.mail}>hello@motions.space</p>
                <span>Copyright 2022 Motions.space Technologies LLC</span>
              </form>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Landingpage;
