import { OnboardingData } from "./models/onboardingData";

export const data = [
  {
    recipient: "john Doe",
    recipient_phone_number: 8066998901,
    recipient_email: "john.doe@example.com",
    delivery_address: "20 wemabod estate, lagos, NG",
    delivery_address_place_id: "place id for delivery location",
    delivery_address_lat: 8.9756849,
    delivery_address_lng: 7.1880845,
    package_description: "my package",
    sender: "Emma Oba",
    sender_phone_number: 9056798765,
    sender_email: "emma@example.com",
    pickup_address: "20 Adeojo street VI, Lagos, NG",
    pickup_address_place_id: "place id for pickup location",
    pickup_address_lat: 9.0519,
    pickup_address_lng: 7.44463,
    delivery_date: "2022-08-25",
    id: 1,
  },
  {
    recipient: "john Doe",
    recipient_phone_number: 8066998901,
    recipient_email: "john.doe@example.com",
    delivery_address: "20 wemabod estate, lagos, NG",
    delivery_address_place_id:
      "EiwyMCBXZW1hYm9kIEVzdCwgSWtlamEgMTAxMjMzLCBJa2VqYSwgTmlnZXJpYSIwEi4KFAoSCcW5Jw4qkjsQEXk6jG3He1fSEBQqFAoSCW3nMBQqkjsQEc-CM-bwCDxY",
    delivery_address_lat: 6.6057695972,
    delivery_address_lng: 3.3417184717,
    package_description: "my package",
    sender: "Emma Oba",
    sender_phone_number: 9056798765,
    sender_email: "emma@example.com",
    pickup_address: "45 Davies St, Mile 12 105102, Lagos",
    pickup_address_place_id: "ChIJSSWcNOaSOxARvvJ9fBWs1Us",
    pickup_address_lat: 6.600090745,
    pickup_address_lng: 3.396820703,
    delivery_date: "2022-08-25",
    id: 2,
  },
];

export const check = [
  {
    recipient: "Emmanuel F.",
    recipient_phone_number: "08066998901",
    recipient_email: "mailto:john.doe@example.com",
    delivery_address: "20 wemabod estate, lagos, NG",
    delivery_address_place_id:
      "EiwyMCBXZW1hYm9kIEVzdCwgSWtlamEgMTAxMjMzLCBJa2VqYSwgTmlnZXJpYSIwEi4KFAoSCcW5Jw4qkjsQEXk6jG3He1fSEBQqFAoSCW3nMBQqkjsQEc-CM-bwCDxY",
    delivery_address_lat: "6.6052867",
    delivery_address_lng: "3.3396558",
    package_description: "my package",
    sender: "Sam O.",
    sender_phone_number: "09056798765",
    sender_email: "mailto:emma@example.com",
    pickup_address: "Ikotun - Idimu Road, Lagos, Nigeria",
    pickup_address_place_id: "ChIJKVin4OePOxARQrIugj_LIoY",
    pickup_address_lat: "6.5628268",
    pickup_address_lng: "3.2744928",
    delivery_date: "2022-08-25",
  },
  {
    recipient: "Bruce Lee",
    recipient_phone_number: "08066998901",
    recipient_email: "mailto:john.doe@example.com",
    delivery_address: "15 Ikorodu - Shagamu Road, Ikorodu, Nigeria",
    delivery_address_place_id: "ChIJRRvjjUnpOxARLutyDdBC58s",
    delivery_address_lat: "6.657342600000001",
    delivery_address_lng: "3.5189644",
    package_description: "my package",
    sender: "Mary Jane",
    sender_phone_number: "09056798765",
    sender_email: "mailto:emma@example.com",
    pickup_address: "Shopeju Street, Lagos, Nigeria",
    pickup_address_place_id: "ChIJ52HvyQCSOxARNRIDHsUG1uU",
    pickup_address_lat: "6.5685525",
    pickup_address_lng: "3.3437321",
    delivery_date: "2022-08-27",
  },
  {
    recipient: "john Doe",
    recipient_phone_number: "08066998901",
    recipient_email: "mailto:john.doe@example.com",
    delivery_address: "Ajayi Bembe Street, Lagos, Nigeria",
    delivery_address_place_id: "ChIJe44AshKLOxARcBdvmvXejys",
    delivery_address_lat: "6.4599728",
    delivery_address_lng: "3.3900589",
    package_description: "my package",
    sender: "Emma Oba",
    sender_phone_number: "09056798765",
    sender_email: "mailto:emma@example.com",
    pickup_address:
      "UNILAG Senate Building, Otunba Payne Street, Lagos, Nigeria",
    pickup_address_place_id: "ChIJ8Xnqr-CMOxARl3DuXL87A1A",
    pickup_address_lat: "6.5194683",
    pickup_address_lng: "3.3987129",
    delivery_date: "2022-08-28",
  },
];

export const onboardingData: OnboardingData[] = [
  {
    bgImage:
      "https://res.cloudinary.com/lara-ng/image/upload/v1665406626/Mask_group_gbjcaq.jpg",
    logo: "https://res.cloudinary.com/lara-ng/image/upload/v1665407002/motions_logo_white_1_tmptou.png",
    heading: "Seamless Deliveries For African Businesses",
    subHeading: "Ready to remove yamayama?",
  },
  {
    bgImage:
      "https://res.cloudinary.com/lara-ng/image/upload/v1665406627/Mask_group_1_ckdcjg.jpg",
    logo: "https://res.cloudinary.com/lara-ng/image/upload/v1665407002/motions_logo_white_1_tmptou.png",
    heading: "Simple Delivery Infrastructure",
    subHeading: "Ready to optimize your dispatch?",
  },
];

export const deliveryPackages = [
  // {
  //   recipient: "Matt Dickson",
  //   packageId: 20462,
  //   recipient_address: "Idumota, Lagos Island , 11056",
  //   date: "Thu,Oct 27 ",
  //   arrival_time: "4:25AM",
  //   depature_time: "4:00AM",
  //   jobStatus: "inTransit",
  //   attempts: 0,
  //   recipient_phone: "",
  //   package_weight: "",
  //   item_value: "",
  //   package_type: "",
  //   sender: "",
  //   sender_phone: "",
  //   pickup_address: "124 Martin street, Idumota, Lagos Island , 11056",
  //   jobType: "Delivery",
  //   jobTitle: "High Court Drop",
  //   handled_by: {
  //     driver_name: "",
  //     driver_phone: "",
  //     driver_display_img: "",
  //     vehicle_type: "",
  //   },
  // },
  // {
  //   recipient: "Trish Nwachi",
  //   packageId: 20463,
  //   recipient_address: "Idumota, Lagos Island , 11056",
  //   date: "Thu,Oct 27 ",
  //   arrival_time: "4:25AM",
  //   depature_time: "4:00AM",
  //   jobStatus: "delivered",
  //   attempts: 0,
  //   recipient_phone: "",
  //   package_weight: "",
  //   item_value: "",
  //   package_type: "",
  //   sender: "",
  //   sender_phone: "",
  //   pickup_address: "124 Martin street, Idumota, Lagos Island , 11056",
  //   jobType: "Delivery",
  //   jobTitle: "Slot phone-Ikoyi",
  //   handled_by: {
  //     driver_name: "",
  //     driver_phone: "",
  //     driver_display_img: "",
  //     vehicle_type: "",
  //   },
  // },
  // {
  //   recipient: "Shalewa Thawn",
  //   packageId: 20464,
  //   recipient_address: "Idumota, Lagos Island , 11056",
  //   date: "Thu,Oct 27 ",
  //   arrival_time: "4:25AM",
  //   depature_time: "4:00AM",
  //   jobStatus: "late",
  //   attempts: 0,
  //   recipient_phone: "",
  //   package_weight: "",
  //   item_value: "",
  //   package_type: "",
  //   sender: "",
  //   sender_phone: "",
  //   pickup_address: "124 Martin street, Idumota, Lagos Island , 11056",
  //   jobType: "Delivery",
  //   jobTitle: "High Court Drop",
  //   handled_by: {
  //     driver_name: "",
  //     driver_phone: "",
  //     driver_display_img: "",
  //     vehicle_type: "",
  //   },
  // },
  // {
  //   recipient: "Kayode Phillips",
  //   packageId: 20465,
  //   recipient_address: "Idumota, Lagos Island , 11056",
  //   date: "Thu,Oct 27 ",
  //   arrival_time: "4:25AM",
  //   depature_time: "4:00AM",
  //   jobStatus: "missed",
  //   attempts: 1,
  //   recipient_phone: "",
  //   package_weight: "",
  //   item_value: "",
  //   package_type: "",
  //   sender: "",
  //   sender_phone: "",
  //   pickup_address: "124 Martin street, Idumota, Lagos Island , 11056",
  //   jobType: "Pickup",
  //   jobTitle: "Asoebi Pick up",
  //   handled_by: {
  //     driver_name: "",
  //     driver_phone: "",
  //     driver_display_img: "",
  //     vehicle_type: "",
  //   },
  // },
  // {
  //   recipient: "Matt Dickson",
  //   packageId: 20466,
  //   recipient_address: "Idumota, Lagos Island , 11056",
  //   date: "Thu,Oct 27 ",
  //   arrival_time: "4:25AM",
  //   depature_time: "4:00AM",
  //   jobStatus: "assigned",
  //   attempts: 0,
  //   recipient_phone: "",
  //   package_weight: "",
  //   item_value: "",
  //   package_type: "",
  //   sender: "",
  //   sender_phone: "",
  //   pickup_address: "124 Martin street, Idumota, Lagos Island , 11056",
  //   jobType: "Delivery",
  //   jobTitle: "High Court Drop",
  //   handled_by: {
  //     driver_name: "",
  //     driver_phone: "",
  //     driver_display_img: "",
  //     vehicle_type: "",
  //   },
  // },
  // {
  //   recipient: "Emmanuella Anthonia",
  //   packageId: 20467,
  //   recipient_address: "Idumota, Lagos Island , 11056",
  //   date: "Thu,Oct 27 ",
  //   arrival_time: "4:25AM",
  //   depature_time: "4:00AM",
  //   jobStatus: "unassigned",
  //   attempts: 0,
  //   recipient_phone: "",
  //   package_weight: "",
  //   item_value: "",
  //   package_type: "",
  //   sender: "",
  //   sender_phone: "",
  //   pickup_address: "124 Martin street, Idumota, Lagos Island , 11056",
  //   jobType: "Pickup",
  //   jobTitle: "Food Pick up",
  //   handled_by: {
  //     driver_name: "",
  //     driver_phone: "",
  //     driver_display_img: "",
  //     vehicle_type: "",
  //   },
  // },
];
