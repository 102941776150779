import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../../assets";
import ImageLogoBackground from "../../components/ImageLogoBackground/ImageLogoBackground";
import PageTitle from "../../components/PageTitle/PageTitle";
import { SIGNUP } from "../../routes/routeConstants";
import styles from "../signUp/SignUp.module.css";

const bgImage =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665435537/Mask_group_2_icgjoy.jpg";

const SignIn = () => {
  return (
    <section className={styles.signInContainer}>
      <ImageLogoBackground bgImage={bgImage} logo={logo} bgHeight="20vh" />
      <PageTitle title="Sign In" subTitle="Let’s get to your dashboard" />

      <form className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">Password</label>
          <input type="password" name="password" id="password" />
        </div>

        <button type="button">Sign In</button>

        <p>
          Not Registered? &nbsp; <Link to={`/${SIGNUP}`}>Sign Up now</Link>
        </p>
      </form>
    </section>
  );
};

export default SignIn;
