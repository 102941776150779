import React from "react";
import motionsLogo from "../../assets/images/motions logo, black.png";
import styles from "./privacypolicy.module.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <div className={styles.page}>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          <div className={styles.brand}>
            <img src={motionsLogo} alt="" />
          </div>
          <div className={styles.navbar_actions}>
            <Link to="/sign-in" type="button" className={styles.btnLogin}>
              Log in
            </Link>
            <Link to="/sign-up" type="button" className={styles.btnSignup}>
              Sign up
            </Link>
          </div>
        </div>
      </nav>
      <Container>
        <div>
          <h1 className={styles.heading}>
            Data Privacy & <span> Protection Notice</span>
          </h1>
          <div className={styles.routeMap}>
            <span>Home</span>
            <span>
              <i className="fa-solid fa-chevron-right"></i>
            </span>
            <span>Privacy policy</span>
          </div>
          <section>
            <em>
              This Data Privacy & Protection Policy (Policy) explains what
              personal data Motions Space Technologies LLC (Motions) collects,
              how we collect it, how we use it, and how we might share it. This
              policy is in accordance with data protection legislation. By
              providing us with your data, you confirm that you have read,
              understood, and accepted the terms of this Policy.
            </em>
          </section>
          <section>
            <h6>Introduction</h6>
            <p>
              We are Motions Space Technologies LLC, an innovative logistics
              infrastructure provider with operations in Lagos, Nigeria and
              headquartered in Nevada, USA. Our mission is to revolutionize the
              movement of goods in Africa, starting with Nigeria, by leveraging
              AI, machine learning, and IoT to make logistics operations
              smarter, more accessible, and stress-free for businesses,
              consumers, and couriers. We collect personal data to improve our
              services and ensure that you are aware of how and why we use your
              data.
            </p>
          </section>

          <section>
            <h6> We Take Your Privacy Seriously </h6>
            <p>
              At Motions, we value your privacy and are committed to protecting
              your data.
            </p>
            <p>
              For our couriers, to offer you the best possible experience, we
              use tracker devices and telematics to monitor your movements and
              optimize your routes. We only use your location data to provide
              and improve our services, which includes real-time updates and
              route optimization.
            </p>
            <p>
              For our business partners and consumers, we collect and use data
              necessary to enable seamless interactions and transactions on our
              platforms. This data helps us to improve our services, personalize
              your experience, and connect you better with couriers and other
              users.
            </p>
            <p>
              Rest assured, we will never share your location data or any
              personal information with third-party advertisers. We use advanced
              encryption and security measures to protect your data from
              unauthorized access. If you have any questions or concerns about
              your data and privacy, please contact us at{" "}
              <a href="mailto:hello@motions.space">hello@motions.space.</a>
            </p>
          </section>
          <section>
            <h6>What Information We Collect</h6>
            <p>
              We collect various types of personal data to provide and enhance
              our services. This includes:
            </p>
            <ul>
              <li>Identity Data: Names, titles, gender, date of birth.</li>
              <li>
                Contact Data: Location addresses, telephone numbers, email
                addresses.
              </li>
              <li>Financial Data: Payment methods, bank details.</li>
              <li>
                Transaction Data: Details of transactions and interactions on
                our platforms.
              </li>
              <li>
                Marketing and Communications Data: Preferences for receiving
                marketing communications.
              </li>
              <li>Location Data: GPS or telematics data (for couriers).</li>
              <li>
                Technical Data: IP addresses, browser types, device information
                (for website visitors).
              </li>
            </ul>
            <p>
              We may also collect other information relevant to our services as
              required."
            </p>
          </section>
          <section>
            <h6>How We Collect Information</h6>
            <p>
              We collect your personal data through various means, including:
            </p>
            <ul>
              <li>
                Direct Interactions: When you sign up, make purchases, or
                interact with our chatbots
              </li>
              <li>
                Financial Services: When you engage in transactions or payment
                processing.
              </li>
              <li>
                Cookies and Similar Technologies: When you visit our website or
                use our applications.
              </li>
              <li>
                Third-Party Sources: We may receive data from third-party
                partners or publicly available sources.
              </li>
            </ul>
          </section>
          <section>
            <h6>Why We Collect Information</h6>
            <p>We collect personal data for the following purposes:</p>
            <ul>
              <li>
                To Provide Our Services: To facilitate transactions, deliver
                goods, and provide support.
              </li>
              <li>
                To Improve Our Services: To analyze usage patterns and enhance
                user experiences.
              </li>
              <li>
                To Communicate with Users: To send updates, offers, and respond
                to inquiries.
              </li>
              <li>
                To Comply with Legal Obligations: To adhere to regulations and
                legal requirements.
              </li>
            </ul>
          </section>
          <section>
            <h6>How We Use Information</h6>
            <p>We use the collected data to:</p>
            <ul>
              <li>Provide, maintain, and improve our services.</li>
              <li>
                Communicate with you about products, offers, and promotions.
              </li>
              <li>Analyze and understand user behavior to tailor services.</li>
              <li>Comply with legal and regulatory requirements.</li>
            </ul>
          </section>
          <section>
            <h6>How We Share Information</h6>
            <p>We may share your personal data with:</p>
            <ul>
              <li>
                Third-Party Service Providers: For payment processing, delivery,
                or other operational needs.
              </li>
              <li>
                Legal Authorities: If required by law or in response to legal
                requests.
              </li>
              <li>
                Business Transfers: In connection with a merger, acquisition, or
                asset sale.
              </li>
              <li>
                With Your Consent: Or as disclosed to you at the time of
                collection.
              </li>
            </ul>
          </section>
          <section>
            <h6>How We Store and Secure Information</h6>
            <p>We are committed to keeping your data safe:</p>
            <ul>
              <li>Storage: Data is stored securely on our servers.</li>
              <li>
                Security Measures: We use encryption, firewalls, and access
                controls.
              </li>
              <li>
                Data Retention: We retain your data for as long as necessary to
                fulfill the purposes for which it was collected.
              </li>
            </ul>
          </section>
          <section>
            <h6>User Rights</h6>
            <p>
              If you are a resident of Nigeria, you may have certain rights
              concerning your personal data, such as:
            </p>
            <ul>
              <li>Access: You can request access to your data.</li>
              <li>
                Correction: You can request corrections to inaccurate data.
              </li>
              <li>Deletion: You can request the deletion of your data.</li>
              <li>
                Complaints: You can lodge complaints with the appropriate
                authorities.
              </li>
            </ul>
          </section>
          <section>
            <h6>Third-Party Websites/Services</h6>
            <p>
              We may use third-party services that collect data. Please refer to
              their respective privacy policies for details.
            </p>
          </section>
          <section>
            <h6>Cookies and Other Tracking Technologies</h6>
            <p>We use cookies and similar technologies to:</p>
            <ul>
              <li>
                Enhance Your Experience: To remember preferences and provide
                personalized content.
              </li>
              <li>
                Analyze Usage: To understand how our website and services are
                used.
              </li>
            </ul>
          </section>
          <section>
            <h6>Contact Information</h6>
            <p>
              If you have any questions or concerns about this privacy policy,
              please contact us at{" "}
              <a href="mailto:hello@motions.space">hello@motions.space.</a> We
              are committed to addressing your inquiries promptly.
            </p>
          </section>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
