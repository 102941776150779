import { configureStore } from "@reduxjs/toolkit";
// eslint-disable-next-line import/named
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { waitingListReducer } from "./waitingListSlice";

const store = configureStore({
  reducer: {
    waiting: waitingListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDisPatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
