import React from "react";
import { Link } from "react-router-dom";
import { SIGNIN } from "../../../routes/routeConstants";
import styles from "./LowLevelInfo.module.css";

export type LowLevelPageData = {
  heading: string;
  subHeading: string;
};

interface PageProps {
  componentData: LowLevelPageData;
}
const LowLevelInfo = (props: PageProps) => {
  const {
    componentData: { heading, subHeading },
  } = props;

  return (
    <article className={styles.mainContainer}>
      <h2>{heading}</h2>
      <p>{subHeading}</p>

      <Link to={`/${SIGNIN}`}>
        <button>Demo Now</button>
      </Link>
    </article>
  );
};

export default LowLevelInfo;
