import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "../../components/Form/Form";
import PageNavbar from "../../components/Navbar/PageNavbar";
import classes from "./FormPage.module.scss";
import twitterImg from "../../assets/icon/Twitter White Icon.svg";
import facebookImg from "../../assets/icon/Facebook White Icon.svg";
import instagramImg from "../../assets/icon/Instagram white icon.svg";
import LinkedinImg from "../../assets/icon/Linkedin White Icon.svg";
import thankuImg from "../../assets/images/thankshands.png";
import useMobileView from "../../hooks/useMobileView";
import { useAppSelector } from "../../redux/store";
import { useNavigate } from "react-router-dom";

const FormPage = () => {
  const mobileViewWidth = useMobileView();
  const {
    waiting: {
      waitingListInfo: { email },
    },
  } = useAppSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate(`/`);
    }
    window.scrollTo(0, 0);
  }, [email, navigate]);
  return (
    <div className={`${classes.container} container-fluid`}>
      <div className={classes.bluebg}>
        <PageNavbar />
        <Container fluid>
          <Row>
            <Col xl={6} lg={7}>
              <Form />
            </Col>
            {mobileViewWidth > 990 && (
              <Col xl={6} lg={5} className={classes.second}>
                <div>
                  <img src={thankuImg} alt="thanks" />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <footer id="footer" className={classes.footer}>
        <Container fluid className={classes.pageBContainer}>
          <Row>
            <Col lg={12}>
              <form>
                <div className={classes.socialLinks}>
                  <a href="https://twitter.com/motions_space">
                    <img src={twitterImg} alt="twitter" />
                  </a>
                  <a href="#!">
                    <img src={facebookImg} alt="facebook" />
                  </a>
                  <a href="https://www.instagram.com/motions.space/">
                    <img src={instagramImg} alt="instagram" />
                  </a>
                  <a href="https://www.linkedin.com/company/motions-space-technologies">
                    <img src={LinkedinImg} alt="linkedin" />
                  </a>
                </div>

                <p className={classes.mail}>hello@motions.space</p>
                <span>Copyright 2022 Motions.space Technologies LLC</span>
              </form>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default FormPage;
