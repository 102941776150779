import React, { useEffect, useState } from "react";
import classes from "./progress.module.scss";

type Prop = {
  done: number;
};

const Progress = (props: Prop) => {
  const { done } = props;
  const [style, setStyle] = useState({});

  useEffect(() => {
    const newStyle = {
      opacity: 1,
      width: `${done}%`,
    };

    setStyle(newStyle);
  }, [done]);

  return (
    <div className={classes.progressbar}>
      <div className={classes.done} style={style}></div>
    </div>
  );
};

export default Progress;
