import React from "react";
import { data } from "../../../data";
import JobCard from "../jobCard/JobCard";
import classes from "./jobs.module.scss";

const Jobs = () => {
  return (
    <div className={classes.jobsContainer}>
      {data.map((value) => (
        <JobCard
          key={value.id}
          data={value}
          active={value.id === 1 ? true : false}
          hasNext={value.id === data.length ? false : true}
        />
      ))}
    </div>
  );
};

export default Jobs;
