import React from "react";
import { icons } from "../../assets";
import styles from "./PageTop.module.css";

type PageProps = {
  title: string;
  date?: string;
};

const PageTop = (props: PageProps) => {
  const { title, date } = props;
  return (
    <div className={styles.pageTop}>
      <div>
        <img src={icons.kolikoIcon} alt="koliko" title="koliko" />
        <img
          src={icons.notifyIcon}
          width="40px"
          alt="minimize"
          title="minimize"
        />
      </div>
      <h3>{title}</h3>
      {date && <span>{date}</span>}
    </div>
  );
};

export default PageTop;
