import React, { useEffect, useState } from "react";
import classes from "./progressSlider.module.scss";

type Prop = {
  done: number;
};

const ProgressSlider = (props: Prop) => {
  const { done } = props;
  const [style, setStyle] = useState({});

  useEffect(() => {
    const newStyle = {
      top: `${done}%`,
      transform: `translate(-50%, -${done}%)`,
    };

    setStyle(newStyle);
  }, [done]);

  return (
    <div className={classes.progressSlider}>
      <div className={classes.sliderThumb} style={style}></div>
    </div>
  );
};

export default ProgressSlider;
