import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets";
import Bot from "../../components/Bot/Bot";
import SingleChannel from "../../components/SingleChannel/SingleChannel";
import { APIPLUGIN, BOTS, MANUALINPUT } from "../../routes/routeConstants";
import AddJobWrapper from "../wrappers/AddJobWrapper/AddJobWrapper";
import styles from "./AddNewJob.module.css";
import { botsData } from "../../utilities/helpers";

const AddNewJob = () => {
  const [selectedId, setSelectedId] = useState(0);
  const [selectedBot, setSelectedBot] = useState("");

  const navigate = useNavigate();

  const checkActiveBot = () => {
    if (selectedBot !== "") {
      navigate(`${BOTS}/${selectedBot}`);
    } else {
      alert("Select a Bot");
    }
  };

  const handleConfirm = () => {
    switch (selectedId) {
      case 1:
        navigate(`${MANUALINPUT}`);
        break;
      case 2:
        console.log("file");
        break;
      case 3:
        navigate(`${APIPLUGIN}`);
        break;
      case 4:
        checkActiveBot();
        break;
      default:
        alert("Select an input channel");
    }
  };

  return (
    <AddJobWrapper addJob={true} title="Add new jobs">
      <article className={styles.channels}>
        <SingleChannel
          id={1}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          icon={icons.manualIcon}
          activeIcon={icons.manualActive}
          channelTitle="Manually Input "
          channelInfo="Manually input job deliveries and details in a form format."
          hasOpenState={false}
          openStateComponent={
            <div className={styles.openState}>
              <p>open state</p>
            </div>
          }
        />
        <div className={styles.lineBreak}></div>
        <SingleChannel
          id={2}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          icon={icons.bulkTasks}
          activeIcon={icons.bulkTasks}
          channelTitle="Import Bulk tasks"
          channelInfo="Got a bulk job manifest? Upload a  CSV, XLS or .TXT file to begin optimzing"
          hasOpenState={true}
          openStateComponent={
            <div className={styles.openState}>
              <div className={styles.fileInput}>
                <label htmlFor="bulktasks" className={styles.label}>
                  <input
                    type="file"
                    id="bulktasks"
                    accept=".txt, .csv, .xls, .xlsx "
                    style={{ display: "none" }}
                  />
                  <img src={icons.fileIcon} alt="import" />
                  <p>Select a File to upload</p>
                </label>
              </div>
              <p className={styles.info}>
                Download sample import file.
                <img src={icons.attach} alt="attach" />
              </p>
            </div>
          }
        />
        <div className={styles.lineBreak}></div>
        <SingleChannel
          id={3}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          icon={icons.apiIcon}
          activeIcon={icons.apiActive}
          channelTitle="Use an API Plugin"
          channelInfo="Connect your existing tools and integrations to the Motions Dashboard"
          hasOpenState={false}
          openStateComponent={
            <div className={styles.openState}>
              <p>open state</p>
            </div>
          }
        />
        <div className={styles.lineBreak}></div>
        <SingleChannel
          id={4}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          icon={icons.greyBot}
          activeIcon={icons.whiteBot}
          channelTitle="Bot Channel"
          channelInfo="Setup a Whatsapp, Telegram or Instagram chatbot to collect your  orders."
          hasOpenState={true}
          openStateComponent={
            <div className={styles.openState}>
              <div className={styles.bots}>
                <Row>
                  {botsData.map((bot) => {
                    return (
                      <Col key={bot.id} xs={4}>
                        <Bot
                          id={bot.id}
                          botName={bot.platform}
                          selectedBot={selectedBot}
                          setSelectedBot={setSelectedBot}
                          icon={bot.icon}
                          activeIcon={bot.activeIcon}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          }
        />
      </article>

      <div className={styles.checkbox_info}>
        <input type="checkbox" name="checkbox" id="checkbox" />
        <label htmlFor="checkbox">
          Remember my selection preference next time.
        </label>
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleConfirm} className={styles.cta}>
          Confirm
        </button>
      </div>
    </AddJobWrapper>
  );
};

export default AddNewJob;
