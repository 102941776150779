import { useEffect, useState } from "react";

const useMobileView = () => {
  const getScreenWidth = () => window.innerWidth;
  const [mobileView, setMobileView] = useState(getScreenWidth());

  useEffect(() => {
    const checkResize = () => {
      setMobileView(() => getScreenWidth());
    };

    window.addEventListener("resize", checkResize);
    return () => window.removeEventListener("resize", checkResize);
  }, []);

  return mobileView;
};

export default useMobileView;
