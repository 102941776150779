import React from "react";
import Ratings from "../../starRatings/Ratings";
import "./modalList.scss";

type Prop = {
  type?: string;
  heading: string;
  subText?: string;
  data: any;
};

const ModalList = ({ type, heading, subText, data }: Prop) => {
  return (
    <div className="modalList">
      <div className="header">
        <h4>{heading}</h4>
        {subText && <p>{subText}</p>}
      </div>
      <div>
        {data.length !== 0 ? (
          <ul>
            {data.map((item: any) => {
              return (
                <li key={item.id} className="list-item">
                  <span className="displayPhoto">
                    <img src="" alt="" />
                  </span>
                  {item.name}
                  {type === "drivers" && (
                    <div className="driverInfo">
                      <Ratings rating={item.rating} />
                      <span>3.6km</span>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <div>No data</div>
        )}
      </div>
    </div>
  );
};

export default ModalList;
