import React from "react";
import { Link } from "react-router-dom";
import box from "../../../assets/images/open box.png";
import add from "../../../assets/icon/add icon white.svg";
import classes from "./noJobs.module.scss";
import { ADDJOB, DASHBOARD } from "../../../routes/routeConstants";

const NoJobs = () => {
  return (
    <div className={classes.page}>
      <div className={classes.content}>
        <img src={box} alt="open box" />

        <div>
          <h1>No Jobs yet</h1>
          <p>Looks like you’re yet to add any delivery jobs</p>
        </div>

        <Link className={classes.addJobs} to={`/${DASHBOARD}/${ADDJOB}`}>
          <img src={add} alt="" /> Add new Job
        </Link>
      </div>
    </div>
  );
};

export default NoJobs;
