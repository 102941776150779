import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import DeliveryCard from "../DeliveryCard/DeliveryCard";
import styles from "./allDeliveries.module.scss";
import modal from "./modal.module.scss";
import edit from "../../../../assets/icon/edit.svg";
import location from "../../../../assets/icon/location.svg";
import optimize from "../../../../assets/icon/optimizing.svg";
import add from "../../../../assets/images/add.png";
import PageTop from "../../../../components/PageTop/PageTop";
import InputLocation from "../InputLocation/InputLocation";
import Progress from "../progressBar/Progress";
import ProgressSlider from "../Slider/ProgressSlider";
import { deliveryPackages } from "../../../../data";
import NoJobs from "../../../../components/mainAppComponents/NoJobs/NoJobs";

interface Job {
  packageId: number;
  jobTitle: string;
  jobStatus: string;
  date: string;
  arrival_time: string;
  depature_time: string;
}

const OptimizeDeliveires = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    setJobs(deliveryPackages);
  }, []);

  const activeJobs = jobs.filter((job) => {
    return (
      job.jobStatus === "inTransit" ||
      job.jobStatus === "assigned" ||
      job.jobStatus === "unassigned"
    );
  });

  return (
    <div className={styles.page}>
      <PageTop title="Optimize My Jobs" />
      <InputLocation title={"Delivery Start Location"} color={"purple"} />

      <div>
        {activeJobs.length !== 0 ? (
          <div>
            <div className={styles.allDeliveries}>
              <div className={styles.actions}>
                <div>
                  <img src={location} alt="" />
                  <p>Route-401</p>
                </div>
                <div>
                  <p>Delete</p>
                  <img src={edit} alt="" />
                </div>
              </div>
              <Row>
                <Col xs={2} lg={1}>
                  <ProgressSlider done={0} />
                </Col>
                <Col xs={10} lg={11}>
                  {activeJobs.map((job) => {
                    return (
                      <DeliveryCard
                        key={job.packageId}
                        jobType={job.jobTitle}
                        jobStatus={job.jobStatus}
                        arrivalDate={job.date}
                        arrivalTime={job.arrival_time}
                        departureDate={job.date}
                        departureTime={job.depature_time}
                      />
                    );
                  })}
                </Col>
              </Row>
            </div>
            <div className={styles.optimizeActions}>
              <button
                className={`${styles.optimizeBtn} ${styles.optimize}`}
                onClick={() => setModalOpen(!modalOpen)}
              >
                Optimize Routes <img src={optimize} alt="" />
              </button>
              <button className={styles.optimizeBtn}>
                <img src={add} alt="" /> Add a new job
              </button>
            </div>
          </div>
        ) : (
          <NoJobs />
        )}
      </div>

      {modalOpen && (
        <div className={modal.modal}>
          <div className={modal.content}>
            <img className={modal.optimize} src={optimize} alt="" />
            <h3>Optimizing your routes</h3>
            <p>2 of 10 Jobs</p>
            <div>
              <Progress done={33} />
            </div>
            <button
              className={modal.close}
              onClick={() => setModalOpen(!modalOpen)}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OptimizeDeliveires;
