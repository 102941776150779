import React, { ReactNode } from "react";
import TopNav from "../../../components/topNav/TopNav";
import styles from "./AddJobWrapper.module.scss";

type PageProps = {
  addJob: boolean;
  title?: string;
  children: ReactNode;
};

const AddJobWrapper = ({ addJob, title, children }: PageProps) => {
  return (
    <section className={styles.addJobWrapper}>
      <TopNav addJob={addJob} title={title} />
      {children}
    </section>
  );
};

export default AddJobWrapper;
