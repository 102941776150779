import { useState } from "react";
import { onboardingData } from "../data";
import { LowLevelPageData } from "../views/onboarding/LowLevelInfo/LowLevelInfo";
import { TopLevelPageData } from "../views/onboarding/TopLevelBackground/TopLevelBackground";

const useOnboarding = () => {
  const [dataId, setDataId] = useState(0);
  const { bgImage, logo, heading, subHeading } = onboardingData[dataId];
  const topLevelData: TopLevelPageData = {
    bgImage,
    logo,
    dataId,
    setDataId,
  };
  const lowLevelInfoData: LowLevelPageData = {
    heading,
    subHeading,
  };

  return {
    topLevelData,
    lowLevelInfoData,
  };
};

export default useOnboarding;
