import React from "react";
import classes from "./heroImage.module.scss";
import onlinePickup from "../../assets/images/Desktop- Header Image.png";
import mobilePickupImg from "../../assets/images/Mobile- Header ImageOnline order pickup .png";
import useMobileView from "../../hooks/useMobileView";

const HeroImage = () => {
  const mobileViewWidth = useMobileView();
  return (
    <div className={classes.heroImage}>
      <img
        src={mobileViewWidth > 768 ? onlinePickup : mobilePickupImg}
        alt="Hero"
      />
    </div>
  );
};

export default HeroImage;
