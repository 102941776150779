import minimizeIcon from "../assets/images/minimize.svg";
import boxAlt from "../assets/images/box-alt 1.png";
import bikingMountain from "../assets/images/biking-mountain.png";
import customer from "../assets/images/customer-satisfaction.png";
import mapMarker from "../assets/images/map-marker 1.png";
import addIcon from "../assets/images/add.png";
import optimizeIcon from "../assets/images/optimize.png";
import micIcon from "../assets/images/mic.png";
import locateIcon from "../assets/images/locate.png";
import shareIcon from "../assets/images/share.png";
import cancelIcon from "../assets/images/cancel.png";
import greyBot from "../assets/icon/greybot.png";
import whiteBot from "../assets/icon/whitbot.png";
import dashboardIcon from "../assets/icon/dashboard.png";
import settingsIcon from "../assets/icon/settings.png";
import routesIcon from "../assets/icon/routes.png";
import reportsIcon from "../assets/icon/reports.png";
import jobsIcon from "../assets/icon/jobs.png";
import notifyIcon from "../assets/icon/notify.png";
import kolikoIcon from "../assets/icon/koliko.png";
import clockImg from "../assets/images/clock.svg";
import bikeImg from "../assets/images/bike.jpg";
import giftImg from "../assets/images/gift.jpg";
import editIcon from "../assets/icon/edit.svg";
import filterIcon from "../assets/icon/filter.svg";
import completedImg from "../assets/icon/completed.png";
import missedImg from "../assets/icon/missed.png";
import hourGlassImg from "../assets/icon/hourGlass.png";
import bikeIcon from "../assets/icon/bike.png";
import bikemanImg from "../assets/images/bikeman.jpg";
import uploadIcon from "../assets/icon/upload.png";
import backIcon from "../assets/icon/backarrow.svg";
import addJobIcon from "../assets/icon/addJobIcon.png";
import fileIcon from "../assets/icon/file.png";
import attach from "../assets/icon/attach.png";
import bulkTasks from "../assets/icon/bulk.png";
import copyIcon from "../assets/icon/copyIcon.png";
import expandDoc from "../assets/icon/Expand document.png";
import apiIcon from "../assets/icon/api.png";
import apiActive from "../assets/icon/apiActive.png";
import manualIcon from "./icon/manual.png";
import manualActive from "./icon/manualActive.png";
import whatsappIcon from "../assets/icon/whatsapp.png";
import instaIcon from "../assets/icon/instagram.png";
import telegramIcon from "../assets/icon/telegram.png";
import whatsappActive from "../assets/icon/whatsappActive.png";
import instaActive from "../assets/icon/instagramActive.png";
import telegramActive from "../assets/icon/telegramActive.png";
import warning from "../assets/icon/warning.png";
import close from "../assets/icon/close.png";
import deliveryIcon from "./icon/delivery.svg";
import pickupIcon from "./icon/pickup.svg";
import forward from "./icon/Foward.svg";
import next from "./icon/next.png";
import locker from "./icon/locker.png";
import lockerActive from "./icon/lockerActive.png";
import location from "./icon/location.png";
import locationActive from "./icon/locationActive.png";
import marker from "./icon/marker.png";
import naijaFlag from "./icon/nigeria.png";
import box from "./icon/box.png";
import descBack from "./icon/descBack.png";
import catClose from "./icon/catClose.png";
import microphone from "./icon/microphone.png";
import mapLocation from "./icon/mapLocation.png";
import ovalPhone from "./icon/ovalPhone.png";
import carret from "./icon/caret.png";
import deliveryDude from "./icon/delivery dude.png";

export const logo =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665407002/motions_logo_white_1_tmptou.png";
export const bgImage =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665435536/Mask_group_3_efj3uq.jpg";
const caret =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665503024/Vector_ztxgej.png";

const caretOpen =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665510253/Vector_1_z4ovlp.png";
const manualInput =
  "https://res.cloudinary.com/lara-ng/image/upload/v1665503024/Group_139_ssl0bc.png";

export const icons = {
  box,
  apiIcon,
  apiActive,
  attach,
  bulkTasks,
  close,
  forward,
  catClose,
  deliveryDude,
  carret,
  descBack,
  ovalPhone,
  next,
  microphone,
  marker,
  naijaFlag,
  whatsappActive,
  location,
  locationActive,
  manualIcon,
  manualActive,
  locker,
  lockerActive,
  whatsappIcon,
  instaActive,
  instaIcon,
  telegramActive,
  telegramIcon,
  copyIcon,
  expandDoc,
  greyBot,
  mapLocation,
  pickupIcon,
  deliveryIcon,
  whiteBot,
  backIcon,
  addJobIcon,
  caret,
  caretOpen,
  manualInput,
  dashboardIcon,
  routesIcon,
  reportsIcon,
  jobsIcon,
  settingsIcon,
  notifyIcon,
  kolikoIcon,
  editIcon,
  filterIcon,
  bikeIcon,
  uploadIcon,
  fileIcon,
  warning,
};
export const dashboardAssets = {
  clockImg,
  bikeImg,
  giftImg,
  completedImg,
  missedImg,
  hourGlassImg,
  bikemanImg,
};
export const homeIcons = {
  minimizeIcon,
  boxAlt,
  bikingMountain,
  customer,
  mapMarker,
  addIcon,
  optimizeIcon,
  micIcon,
  locateIcon,
  shareIcon,
  cancelIcon,
};
