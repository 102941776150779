import React, { useEffect, useState } from "react";
import StatusBox from "../../../../components/mainAppComponents/statusBox/StatusBox";
import { DASHBOARD, TRACKPACKAGE } from "../../../../routes/routeConstants";
import classes from "./singleDelivery.module.scss";
import { Link } from "react-router-dom";

type Props = {
  recipient: string;
  id: number;
  address: string;
  estDate: string;
  time: string;
  jobStatus: string;
  attempts: number;
};

const SingleDelivery = (props: Props) => {
  const { recipient, id, address, estDate, time, jobStatus, attempts } = props;

  const [isActive, setIsActive] = useState(true);
  const [isLate, setIsLate] = useState(false);
  const [isMissed, setIsMissed] = useState(false);

  const showTime = () => {
    if (isLate) {
      return (
        <p className={classes.red}>
          <span>15mins</span> behind
        </p>
      );
    } else if (isMissed) {
      return <p className={classes.green}>{attempts} Attempts</p>;
    }

    return (
      <p
        className={jobStatus === "delivered" ? `${classes.green}` : "intransit"}
      >
        {time}
      </p>
    );
  };

  useEffect(() => {
    const checkStatus = () => {
      if (jobStatus === "delivered") {
        setIsActive(false);
      } else if (jobStatus === "missed") {
        setIsMissed(true);
        setIsActive(false);
        setIsLate(false);
      } else if (jobStatus === "late") {
        setIsLate(true);
        setIsMissed(false);
        setIsActive(true);
      } else if (jobStatus === "inTransit") {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };
    checkStatus();
  }, [jobStatus]);

  return (
    <div className={classes.singleDelivery}>
      <div className={classes.left}>
        <div className={classes.title}>
          <h3>
            {recipient}-#{id}
            {isActive && (
              <span
                className={
                  isLate
                    ? `${classes.beacon} ${classes.late}`
                    : `${classes.beacon}`
                }
              ></span>
            )}
          </h3>
          <p>{address}</p>
        </div>
        <p className={classes.date}>
          Estimated delivery date: <span>{estDate}</span>
        </p>
      </div>
      <div className={classes.right}>
        <Link
          className={classes.trackBtn}
          to={`/${DASHBOARD}/${TRACKPACKAGE}/${id}`}
        >
          <StatusBox innerText={jobStatus} isButton={true} />
        </Link>

        <div className={classes.time}>{showTime()}</div>
      </div>
    </div>
  );
};

export default SingleDelivery;
