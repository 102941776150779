import React from "react";
import classes from "./deliveryCard.module.scss";
import rightCaret from "../../../../assets/icon/Foward.svg";
import StatusBox from "../../../../components/mainAppComponents/statusBox/StatusBox";

type JobProps = {
  jobType: string;
  jobStatus: string;
  arrivalTime: string;
  arrivalDate: string;
  departureTime: string;
  departureDate: string;
};

const DeliveryCard = (props: JobProps) => {
  const {
    jobType,
    jobStatus,
    arrivalTime,
    arrivalDate,
    departureDate,
    departureTime,
  } = props;

  return (
    <div className={classes.deliveryCard}>
      <div className={classes.cardHeader}>
        <h3 className={classes.jobTitle}>{jobType}</h3>
        <div className={classes.status}>
          <StatusBox isButton={false} innerText={jobStatus} />
        </div>
      </div>

      <div className={classes.deliveryDetails}>
        <div>
          <p className={classes.date}>{arrivalDate}</p>
          <h3 className={classes.time}>{arrivalTime}</h3>
          <p className={classes.timeTitle}>Arrival Time</p>
        </div>
        <div>
          <p className={classes.date}>{departureDate}</p>
          <h3 className={classes.time}>{departureTime}</h3>
          <p className={classes.timeTitle}>Departure Time</p>
        </div>
      </div>
      <img className={classes.forward} src={rightCaret} alt="" />
    </div>
  );
};

export default DeliveryCard;
