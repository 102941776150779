import React from "react";
import { Col, Row } from "react-bootstrap";
import classes from "./singleBagBanner.module.scss";
import bagImage from "../../assets/images/Motions Delivery Bag.png";
import check from "../../assets/icon/Vector 3.svg";

const SingleBagBanner = () => {
  return (
    <div className={`${classes.singleBagBannerContainer}`}>
      <Row>
        <Col lg={2} className={classes.bagContainer}>
          <img src={bagImage} alt="bag" />
        </Col>
        <Col lg={9}>
          <div>
            <h3>Fast, secure, convenient deliveries</h3>
            <p>
              For business and personal purposes, say bye to delivery headaches.
            </p>
            <ul>
              <li>
                <div>
                  <img src={check} alt="check" />
                </div>{" "}
                <p>
                  <b>No more-</b> Poor address systems
                </p>
              </li>
              <li>
                <div>
                  <img src={check} alt="check" />
                </div>{" "}
                <p>
                  <b>No more-</b> Failed deliveries due to non-availability
                </p>
              </li>
              <li>
                <div>
                  <img src={check} alt="check" />
                </div>{" "}
                <p>
                  <b>No more-</b> Non-flexible returns
                </p>
              </li>
              <li>
                <div>
                  <img src={check} alt="check" />
                </div>{" "}
                <p>
                  <b>No more-</b> Calling to describe pickup or delivery
                  locations
                </p>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SingleBagBanner;
