import React from "react";
import { useNavigate } from "react-router-dom";
import { homeIcons } from "../../../assets";
import Jobs from "../jobs/Jobs";
import Slider from "../slider/Slider";
import classes from "./deliveryJobs.module.scss";

const DeliveryJobs = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.deliveryJobs}>
      <label htmlFor="location">Delivery Start Location</label>
      <div className={classes.input_group}>
        <p>15 Montgomery road, Yaba Lagos</p>

        <img
          src={homeIcons.mapMarker}
          alt="map marker"
          width="18px"
          height="18px"
          onClick={() => navigate("/maparea")}
        />
      </div>
      <Slider />
      <Jobs />
    </div>
  );
};

export default DeliveryJobs;
