import React from "react";
import classes from "./contact.module.scss";
import { icons } from "../../../../../assets";

type Prop = {
  type: string;
  nextStep: any;
};

const Time = ({ type, nextStep }: Prop) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h6>{type === "Pickup" ? "Pickup Window" : "Drop-off Window"}</h6>
        <p>
          {type === "Pickup"
            ? "Select a date and time “xxx” wants the package picked up."
            : "Select a date and time “xxxxxxxxxxxxxxx” wants the package dropped."}
        </p>
      </div>
      <div className={classes.time}>
        <div className={classes.timeContainer}>
          <div className={classes.formGroup}>
            <input type="date" placeholder="TUE 04 Oct" />
          </div>
          <div className={classes.formGroup}>
            <input type="time" placeholder="5:00PM" />
          </div>
        </div>
        <div>
          <button onClick={nextStep} className={classes.nextBtn}>
            <img src={icons.next} alt="next" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Time;
