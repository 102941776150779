import React from "react";
import useOnboarding from "../../hooks/useOnboarding";
import LowLevelInfo from "./LowLevelInfo/LowLevelInfo";
import styles from "./Onboarding.module.css";
import TopLevelBackground from "./TopLevelBackground/TopLevelBackground";

const Onboarding = () => {
  const { topLevelData, lowLevelInfoData } = useOnboarding();
  return (
    <section className={styles.onboardingContainer}>
      <TopLevelBackground componentData={topLevelData} />
      <LowLevelInfo componentData={lowLevelInfoData} />
    </section>
  );
};

export default Onboarding;
