import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import useMobileView from "../../hooks/useMobileView";
import CallToAction from "../CallToAction/CallToAction";
import HeroImage from "../HeroImage/HeroImage";
import PageNavbar from "../Navbar/PageNavbar";
import classes from "./hero.module.scss";
import dottedBgImg from "../../assets/images/Grid background.svg";

const Hero = () => {
  const mobileviewWidth = useMobileView();
  return (
    <div className={`${classes.HeroContainer} `}>
      <img src={dottedBgImg} alt="dotted background" />
      <div className="container-fluid">
        <Row>
          <Col>
            <PageNavbar />
          </Col>
        </Row>
        <Container fluid className={classes.heroContent}>
          <Row>
            <Col
              lg={7}
              md={mobileviewWidth > 991 ? 6 : 10}
              className={classes.first}
            >
              <CallToAction />
            </Col>
            <Col lg={5} md={6} className={classes.second}>
              <HeroImage />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
