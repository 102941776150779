import React from "react";
import styles from "./TopLevelBackground.module.css";

export type TopLevelPageData = {
  bgImage: string;
  logo: string;
  dataId: number;
  setDataId: any;
};
interface PageProps {
  componentData: TopLevelPageData;
}
const TopLevelBackground = (props: PageProps) => {
  const {
    componentData: { bgImage, logo, dataId, setDataId },
  } = props;
  return (
    <article className={styles.bgContainer}>
      <img src={bgImage} alt="bg" />
      <img src={logo} alt="logo" />

      <div className={styles.dots}>
        <span
          onClick={() => setDataId(() => 0)}
          style={{ backgroundColor: dataId === 0 ? "#fff" : "grey" }}
        ></span>
        <span
          onClick={() => setDataId(() => 1)}
          style={{ backgroundColor: dataId === 1 ? "#fff" : "grey" }}
        ></span>
      </div>
    </article>
  );
};

export default TopLevelBackground;
