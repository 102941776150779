import React, { useEffect, useState } from "react";
import { icons } from "../../../../../assets";
import classes from "./itemDescription.module.scss";

const ItemDescription = () => {
  const [showSize, setShowSize] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [category, setCategory] = useState("Category");
  const [info, setInfo] = useState<{ [key: string]: any }>({});
  const [size, setSize] = useState("small");
  const [quantity, setQuantity] = useState(1);

  const categories = [
    "clothes",
    "documents",
    "electronics",
    "grocery",
    "meals/fresh food",
    "other",
  ];

  useEffect(() => {
    if (size === "small") {
      setInfo({
        heading: "Ideal for light weight items",
        sub: "Cloth, Documents, Int’l Passport.",
        dimension: "12Lx12Wx12H",
        icon: "",
        number: 12,
      });
    } else if (size === "medium") {
      setInfo({
        heading: "Ideal for mid-weight items",
        sub: "Small appliances.",
        dimension: "18Lx16Wx18H",
        icon: "",
        number: 18,
      });
    } else {
      setInfo({
        heading: "Ideal for bulky items",
        sub: "Refrigerator, TV, Microwave Oven",
        dimension: "47Lx31Wx31H",
        icon: "",
        number: 47,
      });
    }
  }, [size]);

  const addQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const reduceQuantity = () => {
    if (quantity === 1) {
      setQuantity(1);
    } else {
      setQuantity((prev) => prev - 1);
    }
  };

  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSize(value);
  };

  return (
    <div className={classes.description}>
      <div
        className={
          showSize ? classes.itemSizeContainerOpen : classes.itemSizeContainer
        }
      >
        <div className={classes.sizeOption}>
          <img onClick={() => setShowSize(!showSize)} src={icons.box} alt="" />
          <div>
            <select name="" id="" onChange={selectChange}>
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="Large">Large</option>
            </select>
          </div>
        </div>
        <div className={classes.sizeInfo}>
          <button
            onClick={() => setShowSize(!showSize)}
            className={classes.closeBtn}
          >
            <img src={icons.descBack} alt="back" />
          </button>
          <h6>{info && info.heading}</h6>
          <p>{info && info.sub}</p>
          <strong>{info && info.dimension}</strong>
          <div className={classes.itemWeight}>
            <input type="number" placeholder="Item weight (kg)" />
          </div>
        </div>
      </div>
      {showCategory && (
        <div className={classes.showCategories}>
          <button
            onClick={() => setShowCategory(!showCategory)}
            className={classes.closeBtn}
          >
            <img src={icons.catClose} alt="close" />
          </button>
          <div className={classes.heading}>
            <p>What are you sending?</p>
            <small>Select a category</small>
          </div>
          <div className={classes.categories}>
            {categories.map((cat) => {
              return (
                <p
                  key={cat}
                  className={cat === category ? classes.catActive : classes.cat}
                  onClick={() => setCategory(cat)}
                >
                  {cat}
                </p>
              );
            })}
          </div>
        </div>
      )}
      <div className={classes.itemInfo}>
        <div className={classes.quantity}>
          <button onClick={reduceQuantity}>-</button>
          {quantity}
          <button onClick={addQuantity}>+</button>
        </div>
        <div
          onClick={() => setShowCategory(!showCategory)}
          className={classes.category}
        >
          {category}
        </div>
        <div className={classes.itemValue}>
          <input type="number" placeholder="Item Value" />
        </div>
        <p>Item weight (kg)</p>
      </div>
    </div>
  );
};

export default ItemDescription;
