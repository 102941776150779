import React from "react";
import styles from "./OrderVolumeBox.module.css";

type PageProps = {
  bgColor: string;
  accentColor: string;
  placeholderText: string;
  value: string;
};

const OrderVolumeBox = (props: PageProps) => {
  const { bgColor, accentColor, placeholderText, value } = props;
  return (
    <div className={styles.orderVolume} style={{ background: bgColor }}>
      <div>
        <p className={styles.place} style={{ background: accentColor }}></p>
        <p className={styles.text}>{placeholderText}</p>
      </div>
      <p>{value}</p>
    </div>
  );
};

export default OrderVolumeBox;
