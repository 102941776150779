import React, { useState } from "react";
import Address from "../FormSteps/ContactDetails/Address";
import Name from "../FormSteps/ContactDetails/Name";
import PhoneNumber from "../FormSteps/ContactDetails/PhoneNumber";
import Time from "../FormSteps/ContactDetails/Time";
import StepsList from "../FormSteps/StepsList/StepsList";

type Prop = {
  progress: any;
  modal?: any;
};

const PickUpandDropOff = ({ progress, modal }: Prop) => {
  const [step, setStep] = useState(0);

  const nextStep = () => {
    if (step === 8) {
      setStep(0);
      progress(0);
    } else {
      setStep((prev) => prev + 1);
      progress(((step + 1) / 8) * 100);
    }
  };

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <StepsList
            nextStep={nextStep}
            hasPickup={true}
            hasDropOff={true}
            showModal={modal}
          />
        );
        break;
      case 1:
        return <Address nextStep={nextStep} type="Pickup" />;
        break;
      case 2:
        return <Name nextStep={nextStep} type="Pickup" />;
        break;
      case 3:
        return <PhoneNumber nextStep={nextStep} type="Pickup" />;
        break;
      case 4:
        return <Time nextStep={nextStep} type="Pickup" />;
        break;
      case 5:
        return <Address nextStep={nextStep} type="Drop-off" />;
        break;
      case 6:
        return <Name nextStep={nextStep} type="Drop-off" />;
        break;
      case 7:
        return <PhoneNumber nextStep={nextStep} type="Drop-off" />;
        break;
      case 8:
        return <Time nextStep={nextStep} type="Drop-off" />;
        break;
      default:
        return (
          <StepsList
            nextStep={nextStep}
            hasPickup={true}
            hasDropOff={true}
            showModal={modal}
          />
        );
    }
  };

  return <div>{displayStep()}</div>;
};

export default PickUpandDropOff;
