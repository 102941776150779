import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React from "react";
// import { useNavigate } from "react-router-dom";
import MapInput from "../../../components/mainAppComponents/mapInput/MapInput";
// import LockerResults from "../../../components/mainAppComponents/mapResults/LockerResults";
import mapStyles from "../../../utilities/mapStyles";
import classes from "./maparea.module.scss";

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

type Prop = {
  hasInput: boolean;
};

const MapArea = ({ hasInput }: Prop) => {
  // const [position, setPosition] = useState();
  const position = { lat: 6.5244, lng: 3.3792 };
  // const navigate = useNavigate();
  return (
    <div className={classes.mapArea}>
      {hasInput && <MapInput />}
      <GoogleMap
        mapContainerClassName={classes.mapContainerStyle}
        zoom={10}
        options={options}
        center={position}
      >
        {position.lat && (
          <Marker
            draggable={true}
            position={position}
            //   icon={markerOptions("confirmLocationPinImg")}
            //   animation="BOUNCE"
            //   onDragEnd={getPinPoint}
            zIndex={100000}
            //   onClick={() => handleActiveMarker(inputValue)}
          >
            <InfoWindow
              position={position}
              // onCloseClick={() => setActiveMarker(null)}
            >
              <div>{10}</div>
            </InfoWindow>
          </Marker>
        )}
      </GoogleMap>
      {/* <div className={classes.cta}>
        <div className={classes.result}>
          <div className={classes.resultContainer}>
            <LockerResults />
            <LockerResults />
            <LockerResults />
          </div>
        </div>
        <button
          className={classes.confirm}
          onClick={() => navigate(`/dashboard/home`)}
        >
          Confirm
        </button>
      </div> */}
    </div>
  );
};

export default MapArea;
