import { Link } from "react-router-dom";
import { icons } from "../../../assets";
import {
  HOME,
  JOBS,
  REPORTS,
  ROUTES,
  SETTINGS,
} from "../../../routes/routeConstants";
import classes from "./mobileNav.module.scss";

const MobileNav = () => {
  return (
    <>
      <div className={classes.mobileNavContainer}>
        <ul>
          <li className={`${classes.list}`}>
            <Link to={HOME}>
              <span className={classes.icon}>
                <img src={icons.dashboardIcon} alt="dashboard" />
              </span>
              <span className={classes.text}>Dashboard</span>
            </Link>
          </li>
          <li className={`${classes.list}`}>
            <Link to={JOBS}>
              <span className={classes.icon}>
                <img src={icons.jobsIcon} alt="jobs" />
              </span>
              <span className={classes.text}>Jobs</span>
            </Link>
          </li>
          <li className={`${classes.list}`}>
            <Link to={ROUTES}>
              <span className={classes.icon}>
                <img src={icons.routesIcon} alt="routes" />
              </span>
              <span className={classes.text}>Routes</span>
            </Link>
          </li>
          <li className={`${classes.list}`}>
            <Link to={REPORTS}>
              <span className={classes.icon}>
                <img src={icons.reportsIcon} alt="reports" />
              </span>
              <span className={classes.text}>Reports</span>
            </Link>
          </li>
          <li className={`${classes.list}`}>
            <Link to={SETTINGS}>
              <span className={classes.icon}>
                <img src={icons.settingsIcon} alt="settings" />
              </span>
              <span className={classes.text}>Settings</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileNav;
