import React from "react";
import TopNav from "../../../components/topNav/TopNav";
import { icons } from "../../../assets";
import styles from "./apiPlugin.module.scss";
import { Link } from "react-router-dom";

const ApiPlugin = () => {
  return (
    <div className={styles.page}>
      <TopNav addJob={false} />
      <div className={styles.content}>
        <div>
          <h1>Plugin API for Jobs</h1>
          <p>
            Input an API URL, API Key and Fetch Parameters to automatically
            process your delivery tasks for further optimization and management.{" "}
          </p>
        </div>
        <div>
          <div className={styles.formGroup}>
            <input type="text" name="url" id="url" placeholder="API URL" />
            <img src={icons.copyIcon} alt="copy" />
          </div>
          <div className={styles.formGroup}>
            <input type="text" name="url" id="url" placeholder="API Key" />
            <img src={icons.copyIcon} alt="copy" />
          </div>
        </div>
        <Link to={"#"} className={styles.docLink}>
          Sample API Documentation <img src={icons.expandDoc} alt="expand" />
        </Link>
      </div>
    </div>
  );
};

export default ApiPlugin;
