import React from "react";
import OrderVolumeBox from "../../../../components/OrderVolumeBox/OrderVolumeBox";
import OverviewChart from "../../../../components/OverviewChart/OverviewChart";
import styles from "./PerformanceOverview.module.scss";

const PerformanceOverview = () => {
  return (
    <div className={styles.container}>
      <h3 className={styles.jobText}>Performance Overview</h3>
      <section className={styles.performance}>
        <OrderVolumeBox
          bgColor="#4169e1"
          accentColor="#203d96"
          placeholderText="Total Order Volume"
          value="200,000"
        />
        <OrderVolumeBox
          bgColor="#55785F"
          accentColor="#2A5837"
          placeholderText="Total Order Value"
          value="$20,000"
        />
        <div className={styles.orderFreq}>
          <OverviewChart />
          <div className={styles.value}>
            <span>Weekly Orders Volume</span> <br />
            <p>$20,000</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PerformanceOverview;
