import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import useMobileView from "../../hooks/useMobileView";
import classes from "./form.module.scss";
import thankuImg from "../../assets/images/thankshands.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useAppSelector } from "../../redux/store";
import { validators } from "../../utilities/validators";
import useInput from "../../hooks/useInput";
import useWaitingList from "../../hooks/useWaitingList";

import { WaitingListInfo } from "../../models/waitinglist.model";

const Form = () => {
  const mobileViewWidth = useMobileView();
  const {
    waiting: { waitingListInfo, loading, countryCode },
  } = useAppSelector((state) => state);
  const { setValuesToState, sendFormData } = useWaitingList();

  const {
    value: firstName,
    inputBlurHandler: firstNameBlurHandler,
    valueInputChange: firstNameInputChange,
    valueIsValid: firstNameIsValid,
    hasError: firstNameHasError,
  } = useInput(validators.checkValueIsEmpty, waitingListInfo?.first_name ?? "");

  const {
    value: lastName,
    inputBlurHandler: lastNameBlurHandler,
    valueInputChange: lastNameInputChange,
    valueIsValid: lastNameIsValid,
    hasError: lastNameHasError,
  } = useInput(validators.checkValueIsEmpty, waitingListInfo?.last_name ?? "");
  const {
    value: phone,
    inputBlurHandler: phoneNumberBlurHandler,
    valueInputChange: phoneNumberInputChange,
    valueIsValid: phoneNumberIsValid,
  } = useInput(validators.checkValueIsEmpty, waitingListInfo?.phone ?? "");

  const [checkedRadioValue, setCheckedRadioValue] = useState("");
  const onRadioChange = (e: any) => {
    setCheckedRadioValue(e.target.value);
  };

  const formIsValid = firstNameIsValid && lastNameIsValid && phoneNumberIsValid;
  const onSubmitForm = (e: any) => {
    e.preventDefault();

    if (!formIsValid) {
      return;
    }
    const waitingList: WaitingListInfo = {
      ...waitingListInfo,
      first_name: firstName,
      last_name: lastName,
      phone,
      interest: checkedRadioValue,
    };
    setValuesToState(waitingList);
    sendFormData(waitingList);
  };

  return (
    <div className={classes.formContainer}>
      <div>
        <h1>Hello</h1>
        <span className={classes.mail}>{waitingListInfo.email}</span>
        <p>
          Thanks for joining our <span>waitlist</span>. Kindly
          <span> fill the form </span>
          below so we can contact you.
        </p>
        {mobileViewWidth < 990 && (
          <Row>
            <Col xl={6} lg={5} className={classes.second}>
              <div>
                <img src={thankuImg} alt="thanks" />
              </div>
            </Col>
          </Row>
        )}
        <form onSubmit={onSubmitForm}>
          <Row>
            <Col sm={6} className={classes.inputContainer}>
              <label htmlFor="fname">First Name</label>
              <input
                type="text"
                name="text"
                id="fname"
                placeholder="Jane"
                onChange={firstNameInputChange}
                onBlur={firstNameBlurHandler}
                value={firstName}
                className={firstNameHasError ? classes.error : ""}
              />
            </Col>
            <Col sm={6} className={classes.inputContainer}>
              <label htmlFor="lname">Last Name</label>
              <input
                type="text"
                name="text"
                id="lname"
                placeholder="Doe"
                onChange={lastNameInputChange}
                onBlur={lastNameBlurHandler}
                value={lastName}
                className={lastNameHasError ? classes.error : ""}
              />
            </Col>
          </Row>
          <Row>
            <Col
              sm={6}
              className={`${classes.inputContainer} ${classes.number}`}
            >
              <label htmlFor="fname">Phone Number</label>
              <PhoneInput
                placeholder="xxx xxxxxxxxx"
                onChange={phoneNumberInputChange}
                onBlur={phoneNumberBlurHandler}
                value={phone}
                defaultCountry={countryCode}
              />
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              className={`${classes.solutionsContainer} ${classes.solutions}`}
            >
              <label htmlFor="solutions">
                I’m interested in Motions All-in-one solution for?
              </label>
              <div id="solutions" className={classes.solutions_radio}>
                <div>
                  <input
                    type="radio"
                    id="Business"
                    name="solutions"
                    value="My Business"
                    onChange={onRadioChange}
                  />
                    <label htmlFor="Business">My Business</label> 
                </div>
                <div>
                  <input
                    type="radio"
                    id="Personal"
                    name="solutions"
                    value="Personal Use"
                    onChange={onRadioChange}
                  />
                   <label htmlFor="Personal">Personal Use</label> 
                </div>
                <div>
                  {" "}
                  <input
                    type="radio"
                    id="Operations"
                    name="solutions"
                    value="Improving Logistics Operations"
                    onChange={onRadioChange}
                  />
                   {" "}
                  <label htmlFor="Operations">
                    Improving Logistics Operations
                  </label>
                </div>
              </div>
            </Col>
          </Row>

          <button
            disabled={!formIsValid}
            type="submit"
            className={formIsValid ? "" : classes.disabled}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            <span>{loading ? " Loading.." : "Submit"}</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
