// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WaitingListInfo, WaitingListType } from "../models/waitinglist.model";

const initialState: WaitingListType = {
  loading: false,
  waitingListInfo: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    interest: "",
  },
  countryCode: "US",
};

const waitingList = createSlice({
  name: "waiting list",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setWaitingListInfo(state, action: PayloadAction<WaitingListInfo>) {
      state.waitingListInfo = action.payload;
    },
    setCountryCode(state, action: PayloadAction<any>) {
      state.countryCode = action.payload;
    },
  },
});

export const waitingListActions = waitingList.actions;
export const waitingListReducer = waitingList.reducer;
