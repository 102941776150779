import React, { useState } from "react";
import classes from "./jobForm.module.scss";
import { icons } from "../../../../assets";
import PickUpandDropOff from "../PickUpandDropOff/PickUpandDropOff";
import DropOffForm from "../DropOffForm/DropOffForm";
import PickUpForm from "../PickUpForm/PickUpForm";

type Props = {
  setProgress: any;
  setModal: any;
};

const JobForm = (props: Props) => {
  const { setProgress, setModal } = props;
  const [hasPickup, setHasPickup] = useState(false);
  const [hasDropOff, setHasDropOff] = useState(false);

  const displayForm = () => {
    if (hasDropOff && hasPickup) {
      return <PickUpandDropOff progress={setProgress} modal={setModal} />;
    } else if (hasDropOff && !hasPickup) {
      return <DropOffForm progress={setProgress} modal={setModal} />;
    }

    return <PickUpForm progress={setProgress} modal={setModal} />;
  };

  return (
    <div className={classes.jobForm}>
      <div>
        <div className={classes.jobType}>
          <div>
            <p className={classes.secondaryTitle}>Job type</p>
            <div className={classes.jobCheckbox}>
              <input
                type="checkbox"
                name="pickup"
                id="pickup"
                className={classes.jobCheck}
                onChange={(e) =>
                  e.target.checked ? setHasPickup(true) : setHasPickup(false)
                }
              />
              <label htmlFor="pickup">
                <span>
                  <img src={icons.pickupIcon} alt="" />
                </span>
                Pickup
              </label>
            </div>
            <div className={classes.jobCheckbox}>
              <input
                type="checkbox"
                name="dropoff"
                id="dropoff"
                className={classes.jobCheck}
                onChange={(e) =>
                  e.target.checked ? setHasDropOff(true) : setHasDropOff(false)
                }
              />
              <label htmlFor="dropoff">
                <span>
                  <img src={icons.deliveryIcon} alt="" />
                </span>
                Drop-off
              </label>
            </div>
          </div>
          <div>
            <p className={classes.secondaryTitle}>Flag as urgent</p>
            <div className={classes.switch}>
              <input
                type="checkbox"
                name="urgent"
                id="urgent"
                className={classes.switchCheck}
              />
              <label htmlFor="urgent">
                <span></span>
              </label>
            </div>
          </div>
        </div>
        <div className={classes.orderDetails}>
          <div>
            <p className={classes.secondaryTitle}>Order Name</p>
            <div className={classes.inputGroup}>
              <input type="text" placeholder="Alex- Slot ikoyi pickup job" />
            </div>
          </div>
          <div>
            <p className={classes.secondaryTitle}>Order #</p>
            <div className={classes.inputGroup}>
              <input type="text" placeholder="P3763" />
            </div>
          </div>
        </div>
      </div>

      <div>{displayForm()}</div>
    </div>
  );
};

export default JobForm;
