import React from "react";
import classes from "./modal.module.scss";
import { icons } from "../../../assets";

type Props = {
  children: any;
  handleClose: any;
};

const Modal = ({ children, handleClose }: Props) => {
  return (
    <div className={classes.modal}>
      <div className={classes.modal__wrapper}>
        <button onClick={handleClose}>
          <img src={icons.close} alt="" />
        </button>
        {children}
      </div>
    </div>
  );
};
export default Modal;
