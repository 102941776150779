import React, { useEffect, useState } from "react";
import search from "../../../../assets/icon/Search.svg";
import edit from "../../../../assets/icon/edit.svg";
import TopNav from "../../../../components/topNav/TopNav";
import { deliveryPackages } from "../../../../data";
import SingleDelivery from "../SingleDelivery/SingleDelivery";
import classes from "./myDeliveries.module.scss";
import NoJobs from "../../../../components/mainAppComponents/NoJobs/NoJobs";

interface Job {
  packageId: number;
  recipient: string;
  recipient_address: string;
  jobStatus: string;
  date: string;
  arrival_time: string;
  attempts: number;
}

const MyDeliveries = () => {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    setJobs(deliveryPackages);
  }, []);

  return (
    <div className={classes.page}>
      <TopNav addJob={false} />
      <h1>My Deliveries</h1>
      <div className={classes.actions}>
        <img src={edit} alt="" />
      </div>
      <div className={classes.searchBox}>
        <img src={search} alt="" />
        <input type="text" placeholder="Search for Job ID, Customer, Product" />
      </div>

      <div>
        {jobs.length !== 0 ? (
          jobs.map((item) => {
            return (
              <SingleDelivery
                key={item.packageId}
                recipient={item.recipient}
                address={item.recipient_address}
                id={item.packageId}
                jobStatus={item.jobStatus}
                estDate={item.date}
                time={item.arrival_time}
                attempts={item.attempts}
              />
            );
          })
        ) : (
          <NoJobs />
        )}
      </div>
    </div>
  );
};

export default MyDeliveries;
