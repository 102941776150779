import React from "react";
import { dashboardAssets, icons } from "../../../../assets";
import styles from "./ActiveJobs.module.scss";

const ActiveJobs = () => {
  return (
    <div className={styles.activeJobs}>
      <div className={styles.heading}>
        <h3>Today's Jobs</h3>
        <div className={styles.actions}>
          <div>
            <img src={icons.editIcon} alt="edit" />
            <span>Edit</span>
          </div>
          <div>
            <img src={icons.filterIcon} alt="filter" />
            <span>Filter</span>
          </div>
        </div>
      </div>
      <div className={styles.options}>
        <div className={styles.option}>
          <img
            src={dashboardAssets.completedImg}
            alt="completed"
            className={styles.completed}
          />
          <span>52</span>
          <p>Completed</p>
        </div>
        <div className={styles.option}>
          <img
            src={dashboardAssets.missedImg}
            alt="missed"
            className={styles.missed}
          />
          <span>3</span>
          <p>Missed</p>
        </div>
        <div className={styles.option}>
          <img
            src={dashboardAssets.hourGlassImg}
            alt="hourglass"
            className={styles.hourglass}
          />
          <span>20</span>
          <p>Unassigned</p>
        </div>
      </div>
    </div>
  );
};

export default ActiveJobs;
