import React from "react";
import { homeIcons } from "../../../assets";
import classes from "./moreView.module.scss";

interface MoreViewProps {
  closeFunction: () => void;
  openCancelFunction: () => void;
  reference: any;
}

const MoreView = (props: MoreViewProps) => {
  const { openCancelFunction } = props;

  return (
    <div className={classes.morePopUp}>
      <div>
        <p>
          View In Map
          <img src={homeIcons.locateIcon} alt="locate" width="12px" />
        </p>
        <p>
          Share Directions
          <img src={homeIcons.shareIcon} alt="locate" width="12px" />
        </p>
        <p onClick={openCancelFunction}>
          Cancel Job
          <img src={homeIcons.cancelIcon} alt="locate" width="12px" />
        </p>
      </div>
    </div>
  );
};

export default MoreView;
