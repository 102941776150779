import React, { useState } from "react";
import { icons } from "../../../assets";
import classes from "./manualJob.module.scss";
import JobForm from "./JobForm/JobForm";
import Modal from "../../../components/mainAppComponents/Modal/Modal";
import { useNavigate } from "react-router-dom";

const ManualJob = () => {
  const [progress, setProgress] = useState(0);
  const [modal, setModal] = useState({
    show: false,
    data: null,
  });

  const navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  const closeModal = () => {
    setModal({
      show: false,
      data: null,
    });
  };

  return (
    <div className={classes.page}>
      {modal.show && <Modal handleClose={closeModal}>{modal.data}</Modal>}
      <div className={classes.pageHeader}>
        <div>
          <h1>Manual Job Input</h1>
          <p>Fill out the delivery job card.</p>
        </div>
        <button onClick={back} className={classes.close}>
          <img src={icons.close} alt="close" />
        </button>
      </div>
      <div className={classes.progress}>
        <div style={{ width: `${progress}%` }}></div>
      </div>
      <div className={classes.content}>
        <JobForm setProgress={setProgress} setModal={setModal} />
        <div></div>
      </div>
    </div>
  );
};

export default ManualJob;
