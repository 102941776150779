import React from "react";
import { icons } from "../../assets";
import SingleRider from "../../components/SingleRider/SingleRider";
import AddJobWrapper from "../wrappers/AddJobWrapper/AddJobWrapper";
import styles from "./Riders.module.scss";
import { useNavigate } from "react-router-dom";
import { ADDRIDER, DASHBOARD, RIDERS } from "../../routes/routeConstants";

const Riders = () => {
  const navigate = useNavigate();

  const addRider = () => navigate(`/${DASHBOARD}/${RIDERS}/${ADDRIDER}`);
  return (
    <AddJobWrapper addJob={false}>
      <div className={styles.heading}>
        <h3>My Riders</h3>
        <p onClick={addRider}>
          <img src={icons.bikeIcon} alt="bike" /> Add New Rider
        </p>
      </div>

      <div className={styles.riderList}>
        <SingleRider
          riderName="Emmanuel Obi"
          vehicleType="Rolls Royce"
          tripCount={300}
        />
        <SingleRider
          riderName="Sam O."
          vehicleType="Mercedez Benz"
          tripCount={100}
        />
        <SingleRider
          riderName="Emmanuel F."
          vehicleType="Lexus"
          tripCount={5000}
        />
        <SingleRider
          riderName="Demilade O."
          vehicleType="G Wagon"
          tripCount={200}
        />
      </div>
    </AddJobWrapper>
  );
};

export default Riders;
