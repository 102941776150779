import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TopNav from "../../../../components/topNav/TopNav";
import copy from "../../../../assets/icon/copy.svg";
import call from "../../../../assets/icon/telephone-fill.svg";
import message from "../../../../assets/icon/chat-dots-fill.svg";
import rider from "../../../../assets/images/delivery dude.png";
import delivery from "../../../../assets/icon/delivery.svg";
import pickup from "../../../../assets/icon/pickup.svg";
import StatusBox from "../../../../components/mainAppComponents/statusBox/StatusBox";
import classes from "./trackPackage.module.scss";
import Stepper from "../../../../components/Stepper/Stepper";
import { deliveryPackages } from "../../../../data";
import MapArea from "../../maparea/MapArea";

interface Job {
  packageId: number;
  jobStatus: string;
  jobType: string;
  date: string;
}

const TrackPackage = () => {
  const params = useParams();
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    setJobs(deliveryPackages);
  }, []);

  const currentPackage = jobs.find((pac) => {
    return pac.packageId === Number(params.id);
  });

  return (
    <div className={classes.page}>
      <TopNav addJob={false} />
      <h1>TrackPackage</h1>
      <p className={classes.packageId}>
        Order <span>#{params.id}</span> <img src={copy} alt="copy" />
      </p>
      <div className={classes.handler}>
        <h4>Handled by:</h4>
        <div className={classes.handlerDetails}>
          <img className={classes.handlerImage} src={rider} alt="" />
          <div>
            <p className={classes.handlerName}>Sunday Ilaje</p>
            <p className={classes.handlerVehicle}>Large van Rider</p>
          </div>
          <div className={classes.icons}>
            <a href="#">
              <img src={call} alt="" />
            </a>
            <a href="#">
              <img src={message} alt="" />
            </a>
          </div>
        </div>
      </div>
      <p className={classes.date}>
        Estimated date of delivery: <span>Thu, 27 Nov</span>
      </p>
      <Stepper status={currentPackage?.jobStatus} />
      <div className={classes.jobInfo}>
        <div className={classes.left}>
          {currentPackage?.jobType === "Delivery" ? (
            <h1>
              Delivery from <img src={delivery} alt="" />
            </h1>
          ) : (
            <h1>
              Pickup from <img src={pickup} alt="" />
            </h1>
          )}

          <div>
            <h3>Matt Dickson</h3>
            <p className={classes.address}>
              124 Martin street, Idumota, Lagos Island , 11056
            </p>
          </div>
          <p className={classes.phone}>(234)-070-8786-4281</p>
        </div>
        <div className={classes.right}>
          <StatusBox isButton={false} innerText="Electronics" />
          <p className={classes.small}>
            Weight: <br /> <span>20kg</span>
          </p>
          <p className={classes.small}>
            Item Value: <br /> ₦ <span>20,000</span>
          </p>
        </div>
      </div>
      <div className={classes.mapouter}>
        <MapArea hasInput={false} />
      </div>
      <button className={classes.repeatBtn}>Repeat Job</button>
    </div>
  );
};

export default TrackPackage;
