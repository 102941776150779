import React from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets";
import styles from "./TopNav.module.scss";

type PageProps = {
  addJob: boolean;
  title?: string;
};

const TopNav = ({ addJob, title }: PageProps) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <aside className={styles.topNav}>
        <img
          className={styles.backImg}
          src={icons.backIcon}
          alt="go back"
          onClick={goBack}
        />

        <>
          <p>
            <img
              src={icons.addJobIcon}
              alt="add job"
              style={{ visibility: addJob ? "visible" : "hidden" }}
            />
            {title}
          </p>
        </>
      </aside>
      {addJob && (
        <p className={styles.outer_p}>Select an input channel to continue</p>
      )}
    </>
  );
};

export default TopNav;
