import React from "react";
import styles from "./OverviewChart.module.scss";

const OverviewChart = () => {
  return (
    <div className={styles.chartContainer}>
      <p>Order Frequencies</p>
      <div className={styles.chartSection}>
        <div>
          <p style={{ height: "80px" }}></p>
          <span>Mon</span>
        </div>
        <div>
          <p style={{ height: "50px" }}></p>
          <span>Tue</span>
        </div>
        <div>
          <p style={{ height: "30px" }}></p>
          <span>Wed</span>
        </div>
        <div>
          <p style={{ height: "90px" }}></p>
          <span>Thur</span>
        </div>
        <div>
          <p style={{ height: "20px" }}></p>
          <span>Fri</span>
        </div>
        <div>
          <p style={{ height: "60px" }}></p>
          <span>Sat</span>
        </div>
        <div>
          <p style={{ height: "40px" }}></p>
          <span>Sun</span>
        </div>
      </div>
    </div>
  );
};

export default OverviewChart;
