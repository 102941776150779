import React, { useState } from "react";
import { icons } from "../../../../../assets";
import classes from "./contact.module.scss";

type Prop = {
  type: string;
  nextStep: any;
};

const Name = (props: Prop) => {
  const { type, nextStep } = props;
  const [isCompany, setIsCompany] = useState(false);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h6>
          {type === "Pickup"
            ? "Sender Contact Details"
            : "Reciever Contact Details"}
        </h6>
        <p>
          {type === "Pickup"
            ? "Who is sending the item?"
            : "Who is receiving the item?"}
        </p>
      </div>
      <div>
        {!isCompany ? (
          <div className={classes.individual}>
            <div className={classes.formGroup}>
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="First Name"
              />
            </div>
            <div className={classes.formGroup}>
              <input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Last Name"
              />
            </div>
          </div>
        ) : (
          <div className={classes.formGroup}>
            <input
              type="text"
              id="companyname"
              name="companyname"
              placeholder="Company or entity name"
            />
          </div>
        )}
        <p className={classes.info}>
          Not an {!isCompany ? "individual" : "entity"} sending?
          <span onClick={() => setIsCompany(!isCompany)}>Switch here</span>
        </p>
      </div>
      <div className={classes.email}>
        <label htmlFor="email" className={classes.bold}>
          {!isCompany ? "An email for First name" : "An email for xxx  entity"}
        </label>
        <div className={classes.inputGroup}>
          <div className={classes.emailInput}>
            <input type="text" placeholder="Email" id="email" name="email" />
          </div>
          <button onClick={nextStep} className={classes.nextBtn}>
            <img src={icons.next} alt="next" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Name;
