import React from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import motionsLogo from "../../assets/images/motions logo, black.png";
import classes from "./navbar.module.scss";

const PageNavbar = () => {
  return (
    <Navbar bg="transparent" variant="dark">
      <Container fluid className={`${classes.customnav} mt-4`}>
        <Row>
          <Col>
            <Navbar.Brand
              href="/"
              className={`d-flex flex-row bd-highlight justify-content-start align-items-center`}
            >
              <img
                alt="Logo"
                src={motionsLogo}
                className="d-inline-block align-top"
                style={{ maxWidth: "130px" }}
              />
            </Navbar.Brand>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default PageNavbar;
