import React from "react";
import { icons } from "../../assets";
import styles from "./SingleRider.module.css";

type PageProps = {
  riderName: string;
  vehicleType: string;
  riderImg?: string;
  tripCount: number;
};

const SingleRider = (props: PageProps) => {
  const { riderName, vehicleType, tripCount } = props;
  return (
    <div className={styles.rider}>
      <div>
        <img src={icons.kolikoIcon} alt="koliko" />
        <div>
          <p>{riderName}</p>
          <span>{vehicleType}</span>
        </div>
      </div>
      <div className={styles.tripCount}>
        <p>{tripCount} Trips</p>
      </div>
    </div>
  );
};

export default SingleRider;
