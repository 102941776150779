import React from "react";
import classes from "./contact.module.scss";
import { icons } from "../../../../../assets";

type Prop = {
  type: string;
  nextStep: any;
};

const PhoneNumber = ({ type, nextStep }: Prop) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h6>
          {type === "Pickup" ? "Sender Phone Number" : "Reciever Phone Number"}
        </h6>
        <p>What’s a reliable number to reach xxx on?</p>
      </div>
      <div className={classes.phone}>
        <div className={classes.country}>
          <img src={icons.naijaFlag} alt="flag" />
        </div>
        <div className={classes.formGroup}>
          <input type="number" placeholder="070" />
        </div>
        <p>-</p>
        <div className={classes.formGroup}>
          <input type="number" placeholder="0000" />
        </div>
        <p>-</p>
        <div className={classes.formGroup}>
          <input type="number" placeholder="0000" />
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <button onClick={nextStep} className={classes.btnConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default PhoneNumber;
