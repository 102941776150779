import React from "react";
import { Col, Row } from "react-bootstrap";
import classes from "./mappingBanner.module.scss";
import mappingGif from "../../assets/images/Motions TSP.gif";
import delightEmoji from "../../assets/images/Delight Emoji.svg";
import dotted from "../../assets/images/dotted.jpg";
import useMobileView from "../../hooks/useMobileView";

const MappingBanner = () => {
  const mobileViewWidth = useMobileView();
  const handleEmojiSize = () => {
    if (mobileViewWidth > 980) {
      return 60;
    } else if (mobileViewWidth > 500) {
      return 80;
    } else {
      return 45;
    }
  };
  return (
    <div className={`${classes.mappingBannerContainer}`}>
      <div className={classes.lightbluebg}></div>
      <div className={classes.dotted}>
        <img src={dotted} alt="dotted" />
      </div>
      <Row className="d-flex align-items-center">
        <Col lg={1}></Col>
        <Col lg={4} className={classes.mappingContainer}>
          <img src={mappingGif} alt="bag" />
        </Col>
        <Col lg={1}></Col>

        <Col lg={6}>
          <div>
            <h3>
              Delight customers with <br className={classes.break} /> seamless
              delivery experiences
            </h3>
            <img
              src={delightEmoji}
              width={handleEmojiSize()}
              height={handleEmojiSize()}
              alt="delight emoji"
              className={classes.delight}
            />
            <p>
              Gift your customers with fast, transparent, stress-free
              deliveries. Enabled by Motions’ AI powered dispatch tools, and
              safe locker infrastructures spread across your city.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MappingBanner;
