import React from "react";
import DashboardTiles from "../../../components/mainAppComponents/dashboardTiles/DashboardTiles";
import PageTop from "../../../components/PageTop/PageTop";
import ActiveJobs from "./ActiveJobs/ActiveJobs";
import classes from "./home.module.scss";
import PerformanceOverview from "./PerformanceOverview/PerformanceOverview";

const Home = () => {
  return (
    <div className={`${classes.home}`}>
      <PageTop date="Wed Aug 10, 2022." title="Dashboard" />
      <DashboardTiles />
      <PerformanceOverview />
      <ActiveJobs />
    </div>
  );
};

export default Home;
