import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DeliveryCard from "../DeliveryCard/DeliveryCard";
import styles from "./allDeliveries.module.scss";
import edit from "../../../../assets/icon/edit.svg";
import location from "../../../../assets/icon/location.svg";
import PageTop from "../../../../components/PageTop/PageTop";
import InputLocation from "../InputLocation/InputLocation";
import ProgressSlider from "../Slider/ProgressSlider";
import { deliveryPackages } from "../../../../data";
import NoJobs from "../../../../components/mainAppComponents/NoJobs/NoJobs";

interface Job {
  packageId: number;
  jobTitle: string;
  jobStatus: string;
  date: string;
  arrival_time: string;
  depature_time: string;
}

const AllDeliveries = () => {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    setJobs(deliveryPackages);
  }, []);

  return (
    <div className={styles.page}>
      <PageTop title="Delivery Jobs" />
      <InputLocation title={"Input Start Location"} color={"blue"} />

      <div className={styles.allDeliveries}>
        <div className={styles.actions}>
          <div>
            <img src={location} alt="" />
            <p>Route</p>
          </div>
          <div>
            <p>Edit</p>
            <img src={edit} alt="" />
          </div>
        </div>

        {!deliveryPackages.length ? (
          <NoJobs />
        ) : (
          <Row>
            <Col xs={2} lg={1}>
              <ProgressSlider done={0} />
            </Col>
            <Col xs={10} lg={11}>
              {jobs.map((job) => {
                return (
                  <DeliveryCard
                    key={job.packageId}
                    jobType={job.jobTitle}
                    jobStatus={job.jobStatus}
                    arrivalDate={job.date}
                    arrivalTime={job.arrival_time}
                    departureDate={job.date}
                    departureTime={job.depature_time}
                  />
                );
              })}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default AllDeliveries;
