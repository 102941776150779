import React from "react";
import classes from "./stepList.module.scss";
import { icons } from "../../../../../assets";
import ItemDescription from "../ItemDescription/ItemDescription";
import ModalList from "../../../../../components/mainAppComponents/ModalList/ModalList";

type Props = {
  hasPickup: boolean;
  hasDropOff: boolean;
  showModal?: any;
  nextStep: any;
};
const StepsList = (props: Props) => {
  const { hasPickup, hasDropOff, showModal, nextStep } = props;

  const drivers = [
    { name: "Badamus Bond", id: 1, rating: 3 },
    { name: "Badamus Bond", id: 2, rating: 3 },
    { name: "Badamus Bond", id: 3, rating: 5 },
    { name: "Badamus Bond", id: 4, rating: 2 },
    { name: "Badamus Bond", id: 5, rating: 3 },
  ];
  return (
    <div className={classes.firstStep}>
      {hasDropOff && (
        <div className={classes.owner}>
          <p className={classes.secondaryTitle}>Specify who owns the package</p>
          <div
            onClick={() =>
              showModal({
                show: true,
                data: (
                  <ModalList
                    type="owner"
                    heading="Specify who owns the package"
                    data={drivers}
                  />
                ),
              })
            }
            className={classes.inputGroup}
          >
            <p>Select from available recievers</p>
          </div>
        </div>
      )}
      <div className={classes.stepList}>
        <div className={classes.stepper}>
          <span className={classes.top}>
            <span>1</span>
          </span>
          <span className={classes.bottom}>
            <span>2</span>
          </span>
          <div className={classes.line}>
            <div></div>
          </div>
        </div>
        <div className={classes.content}>
          {hasDropOff && !hasPickup ? null : (
            <div className={classes.jobDetails}>
              <p>Sender's Details</p>
              <ul>
                <li>
                  <p>Address</p>
                  <img src={icons.forward} alt="" />
                </li>
                <li>
                  <p>Sender Name</p> <img src={icons.forward} alt="" />
                </li>
                <li>
                  <p>Phone Number</p> <img src={icons.forward} alt="" />
                </li>
                <li>
                  <p>Email</p>
                  <img src={icons.forward} alt="" />
                </li>
                <li>
                  <p>Pickup time</p>
                  <img src={icons.forward} alt="" />
                </li>
              </ul>
              {!hasDropOff && <p>No Recipient</p>}
            </div>
          )}

          {hasDropOff && (
            <div className={classes.jobDetails}>
              <p>Reciever's Details</p>
              <ul>
                <li>
                  <p>Address</p> <img src={icons.forward} alt="" />
                </li>
                <li>
                  <p>Reciever Name</p> <img src={icons.forward} alt="" />
                </li>
                <li>
                  <p>Phone Number</p> <img src={icons.forward} alt="" />
                </li>
                <li>
                  <p>Email</p> <img src={icons.forward} alt="" />
                </li>
                <li>
                  <p>Drop-off time</p> <img src={icons.forward} alt="" />
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <ItemDescription />
      <div className={classes.driver}>
        <div>
          <p className={classes.secondaryTitle}>Assign Driver</p>
          <div
            onClick={() =>
              showModal({
                show: true,
                data: (
                  <ModalList
                    type="drivers"
                    heading="Select Driver "
                    subText="Choose from available riders below"
                    data={drivers}
                  />
                ),
              })
            }
            className={classes.inputGroup}
          >
            <p>Select from available dispatchers</p>
          </div>
        </div>
        <div>
          <p className={classes.secondaryTitle}>Auto assign</p>

          <div className={classes.switch}>
            <input
              type="checkbox"
              name="autoAssign"
              id="autoAssign"
              className={classes.switchCheck}
            />
            <label htmlFor="autoAssign">
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div>
        <p className={classes.secondaryTitle}>Note</p>
        <div className={classes.textArea}>
          <textarea
            name="note"
            id="note"
            placeholder="Write or send audio note..."
          ></textarea>
          <button>
            <img src={icons.microphone} alt="voicenote" />
          </button>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <button onClick={nextStep} className={classes.btnConfirm}>
          Next
        </button>
      </div>
    </div>
  );
};

export default StepsList;
