import React from "react";
import classes from "./tapeBanner.module.scss";
import tapeImage from "../../assets/images/tape.svg";
import { Col, Row } from "react-bootstrap";

const TapeBanner = () => {
  return (
    <div className={`${classes.tapeBannerContainer}`}>
      <Row className="d-flex align-items-center">
        <Col lg={5} className={classes.tapeContainer}>
          <img src={tapeImage} alt="tape" />
        </Col>

        <Col lg={5}>
          <div className={classes.content}>
            <h3>Tailored to your needs</h3>
            <p>
              We are building a product that truly works for you, not just
              something that we think you want but something that you need.
            </p>
            <p>
              As we go on this journey we are carrying you along so that we can
              build with you and for you.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TapeBanner;
