import React from "react";
import classes from "./addressOption.module.scss";

type Props = {
  id: number;
  selectedId: number;
  icon: string;
  activeIcon: string;
  text: string;
  setSelectedId: any;
};

const AddressOption = ({
  id,
  selectedId,
  icon,
  activeIcon,
  text,
  setSelectedId,
}: Props) => {
  return (
    <div className={classes.option} onClick={() => setSelectedId(id)}>
      <div className={classes.img}>
        <img src={id === selectedId ? activeIcon : icon} alt="Option" />
      </div>
      <p className={id === selectedId ? classes.active : ""}>{text}</p>
    </div>
  );
};

export default AddressOption;
