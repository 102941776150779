import React from "react";
import { Link } from "react-router-dom";
import classes from "./success.module.scss";
import memeGuy from "../../../../assets/images/sense guy.png";
import { DASHBOARD } from "../../../../routes/routeConstants";
const Succesful = () => {
  return (
    <div className={classes.page}>
      <div className={classes.content}>
        <h3>Success!</h3>
        <img src={memeGuy} alt="" />
        <p>You did it! you saved</p>
        <p className={classes.timeSaved}>
          <span>2</span> minutes
        </p>
        <p>
          That's <span>121</span> seconds of driving
        </p>
        <Link to={`/${DASHBOARD}`}>
          <button className={classes.finish}>Awesome!</button>
        </Link>
      </div>
    </div>
  );
};

export default Succesful;
