import React from "react";
import classes from "./inputLocation.module.scss";
import startFlag from "../../../../assets/icon/StartFlag.svg";
import { Link } from "react-router-dom";
import { DASHBOARD, MAPS } from "../../../../routes/routeConstants";

type Props = {
  title: string;
  color: string;
};

const InputLocation = (props: Props) => {
  const { title, color } = props;
  return (
    <div className={classes.locationInput}>
      <p className={classes.startLocation}>{title}</p>
      <Link
        to={`/${DASHBOARD}/${MAPS}`}
        className={`${classes.btnMaps} ${classes[color]}`}
      >
        Open in maps
      </Link>
      <div className={classes.formGroup}>
        <div className={classes.inputContainer}>
          <img src={startFlag} alt="" />
          <input type="text" placeholder="15 Montgomery road, Yaba Lagos" />
        </div>
      </div>
    </div>
  );
};

export default InputLocation;
