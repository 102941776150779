import React, { useState } from "react";
import AddressOption from "../../../../../components/AddressOption/AddressOption";
import classes from "./contact.module.scss";
import { icons } from "../../../../../assets";
import { Link } from "react-router-dom";
import { DASHBOARD } from "../../../../../routes/routeConstants";

type Prop = {
  type: string;
  nextStep: any;
};

const Address = (props: Prop) => {
  const { type, nextStep } = props;

  const [selectedId, setSelectedId] = useState(0);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h6>{type === "Pickup" ? "Pickup Details" : "Destination Details"}</h6>
        <p>
          {type === "Pickup"
            ? "Choose the preferred  Pickup Option"
            : "Choose the preferred  Drop-off Option"}
        </p>
      </div>
      <div className={classes.options}>
        <AddressOption
          icon={icons.location}
          activeIcon={icons.locationActive}
          text={
            type === "Pickup" ? "Pickup from address" : "Drop-off at address"
          }
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          id={1}
        />
        <p>OR</p>
        <AddressOption
          icon={icons.locker}
          activeIcon={icons.lockerActive}
          text="Nearest safe locker"
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          id={2}
        />
      </div>
      {selectedId === 2 && (
        <div>
          <p className={classes.bold}>Select nearest Locker Pickup </p>
          <p className={classes.info}>
            Motions FREE Pickup Locations eliminates frustrating calls and
            enables you and your dispatchers receive packages at your
            convenience from a nearby safe locker location.
          </p>
        </div>
      )}
      <div className={classes.email}>
        {selectedId === 2 ? (
          <div className={classes.radioGroup}>
            <p className={classes.bold}>Search by:</p>
            <div className={classes.radioOptions}>
              <p className={classes.radioOption}>
                <span></span>
                <input type="radio" name="search" id="address" />
                <label htmlFor="address">Address</label>
              </p>
              <p className={classes.radioOption}>
                <span></span>
                <input type="radio" name="search" id="lga" />
                <label htmlFor="lga">LGA</label>
              </p>
              <p className={classes.radioOption}>
                <span></span>
                <input type="radio" name="search" id="busstop" />
                <label htmlFor="busstop">Bus stop</label>
              </p>
              <p className={classes.radioOption}>
                <span></span>
                <input type="radio" name="search" id="landmark" />
                <label htmlFor="landmark">Landmark</label>
              </p>
            </div>
          </div>
        ) : (
          <p className={classes.bold}>
            {type === "Pickup"
              ? "Set Pickup Destination"
              : "Set Drop-off Destination"}
          </p>
        )}
        <div className={classes.inputGroup}>
          <div className={classes.emailInput}>
            <input
              type="text"
              placeholder="Type in a  full address "
              id="email"
              name="email"
            />
            <Link to={`/${DASHBOARD}/maps`}>
              <img src={icons.marker} alt="marker" />
            </Link>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <button onClick={nextStep} className={classes.btnConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Address;
