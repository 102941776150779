import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import RouteDom from "./routes/routes";
import { ToastProvider } from "react-toast-notifications";

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <RouteDom />
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
