import { icons } from "../assets";

export const drivers = [
  {
    driver: "Sunday Ilajie",
    baseColor: "#CCFFCC",
    textColor: "#43C37E",
  },
  {
    driver: "Gabriel Michael",
    baseColor: "#C6C7CB",
    textColor: "#F6FCFF",
  },
  {
    driver: "Bubu Emilokan",
    baseColor: "#C6C7CB",
    textColor: "#F6FCFF",
  },
];

export const base = "#C6C7CB";
export const text = "#F6FCFF";
export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const formatDate: any = (dateToFormat: string) => {
  const date = new Date(dateToFormat).getDate();
  const month = new Date(dateToFormat).getMonth();
  const day = new Date(dateToFormat).getDay();

  return `${weekDays[day]} ${months[month]} ${date}`;
};

export const scrollUp = () => window.scrollTo(0, 0);

export const botsData = [
  {
    id: 1,
    platform: "whatsapp",
    icon: icons.whatsappIcon,
    activeIcon: icons.whatsappActive,
    designationType: "number",
  },
  {
    id: 2,
    platform: "instagram",
    icon: icons.instaIcon,
    activeIcon: icons.instaActive,
    designationType: "text",
  },
  {
    id: 3,
    platform: "telegram",
    icon: icons.telegramIcon,
    activeIcon: icons.telegramActive,
    designationType: "number",
  },
];
