import React from "react";
import { Link } from "react-router-dom";
import { bgImage, logo } from "../../assets";
import ImageLogoBackground from "../../components/ImageLogoBackground/ImageLogoBackground";
import PageTitle from "../../components/PageTitle/PageTitle";
import { SIGNIN } from "../../routes/routeConstants";
import styles from "./SignUp.module.css";

const SignUp = () => {
  return (
    <section className={styles.signUpContainer}>
      <ImageLogoBackground bgImage={bgImage} logo={logo} bgHeight="15vh" />
      <PageTitle title="Sign Up" subTitle="Register and setup your dashboard" />
      <form className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="companyName">Company Name</label>
          <input type="text" name="name" id="companyName" />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="cr-password">Create Password</label>
          <input type="password" name="cr-password" id="cr-password" />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="co-password">Confirm Password</label>
          <input type="password" name="co-password" id="co-password" />
        </div>
        <button type="button">Sign Up</button>

        <p>
          Already Registered? &nbsp; <Link to={`/${SIGNIN}`}>Sign In now</Link>
        </p>
      </form>
    </section>
  );
};

export default SignUp;
