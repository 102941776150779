import React, { ReactNode, useEffect, useState } from "react";
import caret from "../../assets/icon/caret.png";
import caretOpen from "../../assets/icon/caretOpen.png";

import styles from "./SingleChannel.module.scss";

type PageProps = {
  id: number;
  selectedId: number;
  setSelectedId: any;
  icon: string;
  activeIcon: string;
  channelTitle: string;
  channelInfo: string;
  openStateComponent: ReactNode;
  hasOpenState: boolean;
};

const SingleChannel = ({
  id,
  selectedId,
  setSelectedId,
  icon,
  activeIcon,
  channelTitle,
  channelInfo,
  openStateComponent,
  hasOpenState,
}: PageProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (selectedId !== id) {
      setShow(false);
    }
  }, [selectedId, id]);

  return (
    <div
      className={`${styles.singleChannel}`}
      style={{
        background: selectedId === id ? "#4169E1" : "",
        borderRadius: selectedId === id ? "9px" : "",
      }}
      onClick={() => setSelectedId(id)}
    >
      <div className={styles.channel}>
        <img src={selectedId === id ? activeIcon : icon} alt="icon" />
        <div>
          <p style={{ color: selectedId === id ? "#fff" : "" }}>
            {channelTitle}
          </p>
          <span style={{ color: selectedId === id ? "#fff" : "" }}>
            {channelInfo}
          </span>
        </div>
        {hasOpenState && (
          <button onClick={() => setShow(!show)}>
            <img
              src={show ? caretOpen : caret}
              alt="caret"
              style={{ height: show ? "8px" : "12px", margin: "0" }}
            />
          </button>
        )}
      </div>
      {show && openStateComponent}
    </div>
  );
};

export default SingleChannel;
