import React from "react";
import styles from "./ImageLogoBackground.module.css";

type PageProps = {
  bgImage: string;
  logo: string;
  bgHeight: string;
};
const ImageLogoBackground = ({ bgImage, logo, bgHeight }: PageProps) => {
  return (
    <article className={styles.imgContainer} style={{ height: bgHeight }}>
      <img src={bgImage} alt="bg" />
      <img src={logo} alt="logo" />
    </article>
  );
};

export default ImageLogoBackground;
