import React from "react";
import { useParams } from "react-router-dom";
import TopNav from "../../../components/topNav/TopNav";
import classes from "./botChannel.module.scss";
import { botsData } from "../../../utilities/helpers";
import { icons } from "../../../assets";

const BotChannel = () => {
  const params = useParams();
  //   const [logo, setLogo] = useState(null);

  const currentBot = botsData.find((bot) => {
    return bot.platform === params.type;
  });

  return (
    <div className={classes.page}>
      <TopNav addJob={false} />
      <h1>Setup your Bot</h1>
      <div>
        <form action="#">
          <div className={classes.logo}>
            <label htmlFor="uploadLogo">
              <input
                id="uploadLogo"
                type="file"
                accept=".jpg, .png, . jpeg"
                style={{ display: "none" }}
              />
              <img src={icons.uploadIcon} alt="" />
              <span>Place your company's logo or image</span>
            </label>
          </div>

          <div>
            <div className={classes.formGroup}>
              {currentBot?.designationType === "text" ? (
                <>
                  <p>Deisnated user Handle</p>
                  <div>
                    <input type="text" placeholder="09074301404" />
                  </div>
                </>
              ) : (
                <>
                  <p>Designated Phone Number</p>
                  <div>
                    <input type="number" placeholder="@Zlatan_flashbakes" />
                  </div>
                </>
              )}
            </div>
            <div className={classes.formGroup}>
              <p>
                Bot's Name <img src={currentBot?.activeIcon} alt="" />
              </p>
              <div>
                <input type="text" placeholder="Zlatan" />
              </div>
            </div>
            <div className={classes.formGroup}>
              <p>Customer Support Number</p>
              <div>
                <input type="number" placeholder="09074301404" />
              </div>
            </div>
            <div className={classes.note}>
              <img src={icons.warning} alt="" />
              <p>
                Kindly note this one time Setup could exceed 24hours. You will
                be contacted by our technical team for further assistance.
              </p>
            </div>
          </div>

          <div>
            <button className={classes.submitBtn} type="submit">
              Save and Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BotChannel;
