import React from "react";
import { Link } from "react-router-dom";
import { dashboardAssets } from "../../../assets";
import {
  DASHBOARD,
  MYDELIVERIES,
  RIDERS,
} from "../../../routes/routeConstants";
import classes from "./dashboardTiles.module.scss";

const DashboardTiles = () => {
  return (
    <div className={classes.options}>
      <Link to={`/${DASHBOARD}/${MYDELIVERIES}`}>
        <div className={classes.option}>
          <img src={dashboardAssets.giftImg} alt="gift" />
          <span>52</span>
          <p>My Deliveries</p>
        </div>
      </Link>

      <Link to={`/${DASHBOARD}/${RIDERS}`}>
        <div className={classes.option}>
          <img src={dashboardAssets.bikeImg} alt="gift" />
          <span>11</span>
          <p>My Riders</p>
        </div>
      </Link>
      <Link to={`/${DASHBOARD}/${RIDERS}`}>
        <div className={classes.option}>
          <img src={dashboardAssets.clockImg} alt="gift" />
          <span>242</span>
          <p>Late Deliveries</p>
        </div>
      </Link>
    </div>
  );
};

export default DashboardTiles;
