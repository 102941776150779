import React from "react";
import { dashboardAssets, icons } from "../../assets";
import AddJobWrapper from "../wrappers/AddJobWrapper/AddJobWrapper";
import styles from "./AddRider.module.scss";

const AddRider = () => {
  return (
    <AddJobWrapper addJob={false} title="Add a Rider">
      <div className={styles.bikeImg}>
        <img src={dashboardAssets.bikemanImg} alt="bikeman" />
      </div>
      <form className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="riderName">Rider Name</label>
          <input
            type="text"
            name="name"
            id="riderName"
            placeholder="Sunday Ilashe"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="phoneNum">Phone Number</label>
          <input
            type="number"
            name="number"
            id="number"
            placeholder="+23480342501901"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="riderId">Rider ID</label>
          <input type="text" name="riderId" id="riderId" placeholder="#HBMR1" />
        </div>
        <div className={styles.halfForm}>
          <label htmlFor="vehicleType">Vehicle Type</label>
          <input
            type="text"
            name="vehicleType"
            id="vehicleType"
            placeholder="Yellow Molue"
          />
        </div>
        <div className={styles.halfForm}>
          <label htmlFor="vehicleId">Vehicle ID</label>
          <input
            type="text"
            name="vehicleId"
            id="vehicleId"
            placeholder="LGA-234YE"
          />
        </div>
        <p>Add tracking device/app</p>
        <div className={styles.formGroup}>
          <label htmlFor="riderId">Upload Your Photo</label>
          <div>
            <img src={icons.uploadIcon} alt="upload" />
            <input
              type="file"
              name="riderId"
              id="riderId"
              placeholder="#HBMR1"
              className={styles.upload}
            />
            <p>
              <span>Select </span> &nbsp; an image from your device
            </p>
          </div>

          <button type="button">Confirm</button>
        </div>
      </form>
    </AddJobWrapper>
  );
};

export default AddRider;
