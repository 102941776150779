import React from "react";
import classes from "./statusBox.module.scss";

type Props = {
  isButton?: boolean;
  innerText: string;
};

const StatusBox = (props: Props) => {
  const { isButton, innerText } = props;

  let text;

  if (isButton) {
    switch (innerText) {
      case "delivered":
        text = "Completed";
        break;
      case "missed":
        text = "Missed delivery";
        break;
      case "late":
        text = "Late delivery";
        break;
      case "inTransit":
        text = "Track Package";
        break;
      case "unassigned":
        text = "Track Package";
        break;
      case "assigned":
        text = "Track Package";
        break;

      default:
        text = innerText;
    }
  } else {
    switch (innerText) {
      case "delivered":
        text = "Completed";
        break;
      case "missed":
        text = "Missed delivery";
        break;
      case "late":
        text = "Late delivery";
        break;
      case "inTransit":
        text = "In Transit";
        break;
      case "unassigned":
        text = "Not assigned";
        break;
      case "assigned":
        text = "Assigned";
        break;

      default:
        text = innerText;
    }
  }

  return (
    <div className={`${classes.statusBox} ${classes[innerText]}`}>{text}</div>
  );
};

export default StatusBox;
