import React from "react";
import styles from "./PageTitle.module.css";

type PageProps = {
  title: string;
  subTitle: string;
};
const PageTitle = ({ title, subTitle }: PageProps) => {
  return (
    <div className={styles.headingInfo}>
      <h3>{title}</h3>
      <p>{subTitle}</p>
    </div>
  );
};

export default PageTitle;
