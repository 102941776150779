import React from "react";
import classes from "./ratings.module.scss";

type Prop = {
  rating: number;
};

const Ratings = ({ rating }: Prop) => {
  const ratingPercent = (rating / 5) * 100;

  return (
    <div className={classes.starRatings}>
      <div className={classes.empty}>
        <p>
          <i className="fa fa-star"></i>
        </p>
        <p>
          <i className="fa fa-star"></i>
        </p>{" "}
        <p>
          <i className="fa fa-star"></i>
        </p>{" "}
        <p>
          <i className="fa fa-star"></i>
        </p>{" "}
        <p>
          <i className="fa fa-star"></i>
        </p>
      </div>
      <p style={{ opacity: "0" }}>{ratingPercent}</p>
      <div className={classes.filled} style={{ width: `${ratingPercent}%` }}>
        <p>
          <i className="fa fa-star"></i>
        </p>
        <p>
          <i className="fa fa-star"></i>
        </p>{" "}
        <p>
          <i className="fa fa-star"></i>
        </p>{" "}
        <p>
          <i className="fa fa-star"></i>
        </p>{" "}
        <p>
          <i className="fa fa-star"></i>
        </p>
      </div>
    </div>
  );
};

export default Ratings;
