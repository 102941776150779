import React from "react";
import { base, drivers, text } from "../../../utilities/helpers";
import classes from "./slider.module.scss";

const Slider = () => {
  return (
    <div className={classes.slider}>
      {drivers.map((item, id) => (
        <p
          key={id}
          style={{
            background: item.baseColor,
            color: item.textColor,
            border: `1px solid ${item.textColor}`,
          }}
        >
          {item.driver}
        </p>
      ))}
      <p
        style={{
          background: base,
          color: text,
          border: `1px solid ${text}`,
        }}
      >
        Add New Driver
      </p>
    </div>
  );
};

export default Slider;
