import React, { useState } from "react";
import classes from "./mapInput.module.scss";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { icons } from "../../../assets";

const searchOptions = {
  componentRestrictions: { country: "ng" },
  types: ["geocode"],
};

const MapInput = () => {
  const [address, setAddress] = useState("");
  const handleAddress = (value: string) => {
    setAddress(() => value);
  };
  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);

    const latlng = await getLatLng(results[0]);

    setAddress(value);
    console.log({ placeId: results[0].place_id, latlng });
  };

  return (
    <div className={classes.inputContainer}>
      <label htmlFor="destination">Pin your start location on the map</label>
      <PlacesAutocomplete
        value={address}
        onChange={handleAddress}
        searchOptions={searchOptions}
        onSelect={handleSelect}
        shouldFetchSuggestions={address.length > 2}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }: any) => (
          <>
            <input
              {...getInputProps({
                placeholder: "15 Montgomery road, Yaba Lagos",
                type: "text",
              })}
            />
            <div
              className={`autocomplete-dropdown-container ${classes.dropdown}`}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion: any, id: number) => {
                return (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    className={classes.suggestion}
                    key={id}
                  >
                    <span>
                      <img src={icons.mapLocation} alt="" />
                    </span>
                    <span className={classes.list_item}>
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default MapInput;
