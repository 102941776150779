import { useToasts } from "react-toast-notifications";
import { HttpConfig } from "../models/https";
import { WaitingListInfo } from "../models/waitinglist.model";
import { useAppDisPatch } from "../redux/store";
import { waitingListActions } from "../redux/waitingListSlice";
import useHttps from "./useHttps";

const useWaitingList = () => {
  const dispatch = useAppDisPatch();
  const sendRequest = useHttps();
  const { addToast } = useToasts();

  const setValuesToState = (value: WaitingListInfo) => {
    dispatch(waitingListActions.setWaitingListInfo(value));
  };

  const setLoading = (value: boolean) => {
    dispatch(waitingListActions.setLoading(value));
  };

  const sendFormData = (waitinglist: WaitingListInfo) => {
    const config: HttpConfig = {
      url: "/wait-list",
      method: "post",
      data: waitinglist,
    };

    const success = () => {
      addToast(
        `Thanks for filling out the form.
       We would reach out to you shortly`,
        {
          autoDismiss: true,
          appearance: "success",
        }
      );
      const resetWaitingList: WaitingListInfo = {
        last_name: "",
        first_name: "",
        email: "",
        phone: "",
        interest: "",
      };
      dispatch(waitingListActions.setWaitingListInfo(resetWaitingList));
    };
    sendRequest(config, success, setLoading);
  };
  return {
    setValuesToState,
    sendFormData,
  };
};

export default useWaitingList;
