import React, { useEffect, useState } from "react";
import classes from "./stepper.module.scss";
import check from "../../assets/icon/check-white.svg";

type Prop = {
  status: any;
};

const Stepper = (props: Prop) => {
  const { status } = props;
  const steps = ["Job assigned", "In transit", "Delivered"];
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    switch (status) {
      case "delivered":
        setCurrentStep(4);
        break;
      case "missed":
        setCurrentStep(2);
        break;
      case "late":
        setCurrentStep(3);
        break;
      case "inTransit":
        setCurrentStep(3);
        break;
      case "assigned":
        setCurrentStep(2);
        break;

      default:
        setCurrentStep(1);
    }
  }, [status]);

  return (
    <div className={classes.stepper}>
      {steps?.map((step, i) => (
        <div
          className={`${classes.stepItem} ${
            i + 1 === currentStep && classes.active
          } ${i + 1 < currentStep && classes.complete}`}
          key={i}
        >
          <div className={classes.step}>
            {i + 1 < currentStep && <img src={check} alt="" />}
          </div>
          <p>{step}</p>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
