export const ONBOARDING = "onboarding";
export const SIGNUP = "sign-up";
export const SIGNIN = "sign-in";
export const SETUP = "dashboard-setup";
export const ADDJOB = "add-job";
export const DASHBOARD = "dashboard";
export const ROUTES = "routes";
export const REPORTS = "reports";
export const SETTINGS = "settings";
export const JOBS = "jobs";
export const HOME = "home";
export const RIDERS = "riders";
export const ADDRIDER = "add-rider";
export const MAPS = "maps";
export const FORM = "fillform";
export const DELIVERIES = "deliveries";
export const MYDELIVERIES = "my-deliveries";
export const TRACKPACKAGE = "track-package";
export const MANUALINPUT = "manual-job";
export const APIPLUGIN = "api-plugin";
export const BOTS = "bots";
