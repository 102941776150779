import React from "react";
import MapArea from "../maparea/MapArea";
import classes from "./maps.module.scss";
import { useNavigate } from "react-router-dom";
// import LockerResults from "../../../components/mainAppComponents/mapResults/LockerResults";
// import AddressResult from "../../../components/mainAppComponents/mapResults/AddressResult";

const Maps = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.maps}>
      <MapArea hasInput={true} />
      <div className={classes.cta}>
        {/* <div className={classes.result}>
          <span className={classes.dragger}></span>
          <div className={classes.resultContainer}>
            {/* <LockerResults />
            <LockerResults />
            <LockerResults />
            <AddressResult /> */}
        {/* </div>
        </div> */}
        */
        <button
          className={classes.confirm}
          onClick={() => navigate(`/dashboard/home`)}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Maps;
