import axios from "axios";
import { useCallback } from "react";
import { HttpConfig } from "../models/https";
// eslint-disable-next-line import/no-unresolved
import { useToasts } from "react-toast-notifications";

const useHttps = () => {
  const { addToast } = useToasts();
  const sendRequest = useCallback(
    async (
      httpConfig: HttpConfig,
      callback: (data: any) => void,
      loadingFunc: (value: boolean) => void | null
    ) => {
      const apiUrl = `${process.env.REACT_APP_API_URL}${httpConfig.url}`;

      const config = {
        ...httpConfig,
        url: apiUrl,
        headers: {
          "Content-Type": "application/json",
          ...httpConfig.headers,
        },
      };

      try {
        if (loadingFunc) {
          loadingFunc(true);
        }

        const response = await axios(config);
        callback(response.data);
        if (loadingFunc) {
          loadingFunc(false);
        }
      } catch (err: any) {
        addToast(err?.response?.data?.message ?? "Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
        if (loadingFunc) {
          loadingFunc(false);
        }
      }
    },

    [addToast]
  );
  return sendRequest;
};

export default useHttps;
