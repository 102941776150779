import { Navigate, Route, Routes } from "react-router-dom";
import DashboardPage from "../views/dashboard/DashboardPage";
import FormPage from "../views/formpage/FormPage";
import Landingpage from "../views/landingpage/Landingpage";
import Onboarding from "../views/onboarding/Onboarding";
import SignIn from "../views/signIn/SignIn";
import SignUp from "../views/signUp/SignUp";
import { DASHBOARD, FORM, ONBOARDING, SIGNIN, SIGNUP } from "./routeConstants";
import PrivacyPolicy from "../views/Privacy Policy/PrivacyPolicy";

const RouteDom = () => {
  return (
    <Routes>
      <Route path={`/${SIGNIN}`} element={<SignIn />} />
      <Route path={`/${SIGNUP}`} element={<SignUp />} />
      <Route path={`/${ONBOARDING}`} element={<Onboarding />} />
      <Route path={`/${DASHBOARD}/*`} element={<DashboardPage />} />
      <Route path={`/${FORM}`} element={<FormPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/" element={<Landingpage />} />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};

export default RouteDom;
